//@flow
import Overview from './components/Pages/Overview';
import Auth from './components/Pages/Auth';
import Versions from './components/Pages/Versions';
import ErrorCodes from './components/Pages/ErrorCodes';
import Sandbox from './components/Pages/Sandbox';
import Changelog from './components/Pages/Changelog';
import Endpoints from './components/Pages/Endpoints';
import Home from './components/Pages/Home';
import Environments from './components/Pages/Environments';
import RateLimiting from './components/Pages/RateLimiting';
import HandlingRateLimiting from './components/Pages/HandlingRateLimiting';

const routes = [
  {
    path: '/',
    title: 'Overview',
    exact: true,
    component: Home
  },
  {
    path: '/demo',
    title: 'Demo',
    exact: true,
    component: Sandbox
  },
  {
    path: '/changelog',
    title: 'Changelog',
    exact: true,
    component: Changelog
  }
];

export const mainRoutes = [
  {
    path: '/',
    title: 'Overview',
    id: 'overview',
    component: Overview
  },
  {
    path: '/',
    title: 'Authentication',
    id: 'auth',
    component: Auth
  },
  {
    path: '/',
    title: 'Versioning',
    id: 'version',
    component: Versions
  },
  {
    path: '/',
    title: 'Environments',
    id: 'environments',
    component: Environments
  },
  {
    path: '/',
    title: 'Error Codes',
    id: 'errors',
    component: ErrorCodes
  },
  {
    path: '/',
    title: 'Rate Limiting',
    id: 'rate-limiting',
    component: RateLimiting
  },
  {
    path: '/',
    title: 'Handling Rate Limiting',
    id: 'handling-rate-limiting',
    component: HandlingRateLimiting
  },
  {
    path: '/',
    title: 'API Endpoints',
    id: 'endpoints',
    component: Endpoints,
    hideSidebar: true
  }
];

export default routes;
