//@flow
import styled from 'styled-components';

export const Notes = styled.div`
  padding: 0 0 12px;
`;

export const Section = styled.div`
  padding: 12px 0 32px;
  border-bottom: 1px solid #ececec;
`;

export const URLWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  margin-bottom: 12px;
`;

export const ParamType = styled.span`
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
`;

export const Method = styled.div`
  text-align: center;
  text-transform: uppercase;
  background: ${({ method }) =>
    method === 'POST'
      ? '#f05829'
      : method === 'DELETE'
      ? '#ff3333'
      : method === 'PATCH'
      ? '#51baf7'
      : 'rgb(0, 77, 126)'};
  padding: 6px 8px;
  color: #fff;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
`;

export const Required = styled.span`
  color: #f05829;
`;

export const URL = styled.div`
  padding: 6px 12px 6px 6px;
  background: #ececec;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
`;

export const APIWrapper = styled.div`
  @media (min-width: 1280px) {
    display: flex;
  }
`;

export const Request = styled.div`
  pre {
    word-wrap: break-word;
  }
`;

export const Docs = styled.div`
  padding-bottom: 24px;

  @media (min-width: 1280px) {
    flex: 0 0 450px;
    padding-right: 12px;
  }
`;

export const CodeWrapper = styled.div`
  @media (min-width: 1280px) {
    flex: 1 0 auto;
    padding-left: 12px;
  }
`;

export const Code = styled.div`
  overflow-x: auto;
  background: #33383b;
  padding: 8px 24px;
  border-radius: 4px;
  color: #fff;
  max-height: 85vh;

  a {
    max-width: 400px;
    display: inline-block;
    word-break: break-all;
  }

  pre {
    max-width: 280px;
  }

  h3 {
    color: #d0d4d7;
  }

  .__json-pretty__ {
    line-height: 1.3;
    color: rgba(248, 248, 242, 1);
  }

  .__json-key__ {
    color: #51baf7;
  }

  .__json-value__ {
    color: #e85e5e;
  }

  .__json-string__ {
    color: #d0d0d0;
  }
  .__json-boolean__ {
    color: #e47b00;
  }
`;

export const StyledPre = styled.code`
  display: inline;
  background: #ececec;
  border: #ccc;
  color: #e47b00;
  padding: 2px 4px;
  border-radius: 3px;
`;

export const StyledListItem = styled.li`
  line-height: 1.5;
`;

export const BoldSpan = styled.span`
  font-weight: bold;
`;
