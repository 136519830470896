import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Section } from '../../util/styles';

export default function Overview(props) {
  return (
    <Section>
      <h1>API Overview</h1>
      <Typography>
        The Tekmetric API allows shop owners to grant read only access to their Customer, Vehicle, and Repair Order data
        to 3rd party applications through a RESTful web service. Our API uses resource-oriented URLS, accepts query
        strings, returns JSON responses, and uses standard HTTP response codes, authentication, and verbs.
      </Typography>
    </Section>
  );
}
