//@flow
import React from 'react';
import { BoldSpan, Section, StyledListItem, StyledPre } from '../../util/styles';

export default function RateLimiting(props) {
  return (
    <Section>
      <h2>Rate Limiting</h2>
      <p>
        Our API enforces rate limits to maintain optimal performance and prevent abuse. The default limit is <StyledPre>600
        requests per minute</StyledPre>. Sandbox
        accounts are limited to <StyledPre>300 API calls per minute</StyledPre>. To avoid disruptions:
      </p>
      <ul>
        <StyledListItem>
          <BoldSpan>Avoid Burst Traffic</BoldSpan>: Distribute your requests evenly over time rather than sending large
          bursts.
        </StyledListItem>
        <StyledListItem>
          <BoldSpan>Consequences of Repeated Bursting</BoldSpan>: Repeatedly exceeding the rate limit may lead to
          further restrictions on
          your
          API usage.
        </StyledListItem>
      </ul>

      <p>
        If you are querying a large amount of data within a short timeframe, the API's response code will indicate
        that you've exceeded the quota available for your API Key by returning an HTTP status
        code <StyledPre>429</StyledPre> and an empty body.
      </p>
    </Section>
  );
}
