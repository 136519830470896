//@flow
import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import JSONPretty from 'react-json-pretty';
import {
  Request,
  URLWrapper,
  Notes,
  Method,
  ParamType,
  URL,
  APIWrapper,
  Docs,
  Code,
  CodeWrapper,
  Required
} from '../../../util/styles';

export default function APIDocumentation({ endpoint }) {
  return (
    <Fragment>
      <h2>{endpoint.name}</h2>

      <APIWrapper>
        <Docs>
          <div>
            <Notes>{endpoint.notes}</Notes>
            <Table padding="dense">
              {endpoint.headers && endpoint.headers.length > 0 && (
                <Fragment>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>Request Headers</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {endpoint.headers.map((header, i) => (
                      <TableRow>
                        <TableCell>
                          <strong>{header.name}</strong>
                        </TableCell>
                        <TableCell>{header.note}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Fragment>
              )}
              {endpoint.parameters && endpoint.parameters.length > 0 && (
                <Fragment>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>Request Parameters</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {endpoint.parameters.map((param, i) => (
                      <TableRow>
                        <TableCell>
                          <strong>{param.name}</strong>
                          <br />
                          <ParamType>{param.type}</ParamType>
                        </TableCell>
                        <TableCell>
                          {param.note} <br />
                          {param.values && (
                            <>
                              <strong>Permitted Values:</strong>&nbsp;[&nbsp;
                              {param.values.map(
                                (val, i) =>
                                  `${val.value}${val.value !== val.label ? ` - (${val.label})` : ''}${
                                  param.values.length - 1 > i ? ', ' : ' '
                                  }`
                              )}
                              ]
                            </>
                          )}
                          {param.default && (
                            <>
                              <strong>Default Value:</strong>&nbsp; {param.default}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Fragment>
              )}
              {endpoint.requestAttributes && endpoint.requestAttributes.length > 0 && (
                <Fragment>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>Request Attributes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {endpoint.requestAttributes.map((param, i) => (
                      <TableRow>
                        <TableCell>
                          <strong>{param.name}</strong>
                          <br />
                          <ParamType>{param.type}</ParamType>
                          <br />
                          {param.required &&
                            <Required>required</Required>
                          }
                        </TableCell>
                        <TableCell>
                          {param.note} <br />
                          {param.values && (
                            <>
                              <strong>Permitted Values:</strong>&nbsp;[&nbsp;
                              {param.values.map(
                                (val, i) =>
                                  `${val.value}${val.value !== val.label ? ` - (${val.label})` : ''}${
                                  param.values.length - 1 > i ? ', ' : ' '
                                  }`
                              )}
                              ]
                            </>
                          )}
                          <br/>
                          {param.default && (
                            <>
                              <strong>Default Value:</strong>&nbsp; {param.default}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Fragment>
              )}
              {endpoint.attributes && endpoint.attributes.length > 0 && (
                <Fragment>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>Response Attributes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {endpoint.attributes.map((attr, i) => (
                      <TableRow>
                        <TableCell>
                          <strong>{attr.name}</strong>
                          <br />
                          <ParamType>{attr.type}</ParamType>
                        </TableCell>
                        <TableCell>{attr.note}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Fragment>
              )}
            </Table>
          </div>
        </Docs>

        <CodeWrapper>
          <URLWrapper>
            <Method method={endpoint.method}>{endpoint.method}</Method>
            <URL>{endpoint.url}</URL>
          </URLWrapper>
          <Code>
            <h3>Example Request</h3>
            <Request>{endpoint.request()}</Request>

            <h3>Example Response</h3>
            <JSONPretty data={endpoint.response} />
          </Code>
        </CodeWrapper>
      </APIWrapper>
    </Fragment>
  );
}
