//@flow
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Section } from '../../util/styles';

export default function Environments(props) {
  return (
    <Section>
      <h2>Environments</h2>
      <p>
        Tekmetric provides both a Test and Production environment for it's public API. We encourage users to use the
        Test environment to experiment with and develop against.
      </p>
      <p>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Environment</TableCell>
              <TableCell>Hostname</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <strong>Sandbox</strong>
              </TableCell>
              <TableCell>
                <a href="https://sandbox.tekmetric.com">https://sandbox.tekmetric.com</a>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Production</strong>
              </TableCell>
              <TableCell>
                <a href="https://shop.tekmetric.com">https://shop.tekmetric.com</a>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </p>
    </Section>
  );
}
