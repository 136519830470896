//@flow
import React from 'react';
import APIDocumentation from './APIDocumentation';
import endpoints from '../../../util/endpoints';

export default function ErrorCodes(props) {
  return (
    <div>
      <h2>API Endpoints</h2>

      {endpoints.map((endpoint, index) => (
        <div id={`endpoint-${index}`} name={`endpoint-${index}`}>
          <APIDocumentation endpoint={endpoint} />
        </div>
      ))}
    </div>
  );
}
