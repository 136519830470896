import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { withRouter, Link as NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import { mainRoutes } from '../../routes';
import endpoints from '../../util/endpoints';

function Sidebar(props) {
  return (
    <SideBar>
      <NavWrapper>
        <Title>General</Title>
        <UL>
          {mainRoutes
            .filter(route => route.hideSidebar !== true)
            .map((route, index) => (
              <li>
                <Link location={props.location} to={`${route.id}`} name={route.title} />
              </li>
            ))}
        </UL>

        <Title>API</Title>
        <UL>
          {Object.values(endpoints).map((endpoint, index) => (
            <li>
              <Link location={props.location} to={`endpoint-${index}`} name={endpoint.name} />
            </li>
          ))}
        </UL>

        <Title>Changelog</Title>
        <UL>
          <li>
            <NavLink to="/changelog">2019-2024</NavLink>
          </li>
        </UL>
      </NavWrapper>

      <ButtonWrapper>
        <NavLink to="/demo">
          <Button variant="outlined" color="secondary">
            Test Drive
          </Button>
        </NavLink>
      </ButtonWrapper>
    </SideBar>
  );
}

export default withRouter(Sidebar);

const Link = ({ location, name, to }) => {
  if (location.pathname === '/') {
    return (
      <ScrollLink smooth offset={-80} spy={true} duration={500} activeClass="active" to={to}>
        {name}
      </ScrollLink>
    );
  } else {
    return <NavLink to={`/#${to}`}>{name}</NavLink>;
  }
};

const ButtonWrapper = styled.div`
  padding-left: 30px;
`;

const SideBar = styled.div`
  position: fixed;
  left: 0px;
  top: 64px;
  height: calc(100vh - 65px);
  overflow-y: auto;
  width: 250px;
  padding-bottom: 70px;
`;

const NavWrapper = styled.div`
  padding: 12px 24px;
`;

const Title = styled.h3`
  text-transform: uppercase;
  font-weight: 400;
  color: rgb(82, 82, 82);
  margin: 10px 0 10px;
`;

const UL = styled.ul`
  list-style: none;
  padding-left: 10px;
  margin: 0 0 20px;

  a {
    color: #000;
    display: block;
    padding: 3px 0;
  }

  a.active {
    color: #f05829;
  }

  a:hover {
    text-decoration: underline;
  }
`;
