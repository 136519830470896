import React from 'react';
import styled from 'styled-components';

function Footer(props) {
  return (
    <FooterWrapper>
      <p>&copy; Tekmetric {new Date().getFullYear()}</p>
    </FooterWrapper>
  );
}

export default Footer;

const FooterWrapper = styled.div`
  background: #435c67;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  padding: 8px 16px;
  z-index: 100;
`;
