// @flow
import omitBy from 'lodash/omitBy';

export function jsonToParam(obj: Object) {
  obj = omitBy(obj, val => !val);

  var url = Object.keys(obj)
    .map(function(key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
    })
    .join('&');

  return url;
}

export function updatePathWithParams(url: string, params: Array) {
  params.forEach(param => {
    url = url.replace(`{${param.name}}`, param.value);
  });

  return url;
}
