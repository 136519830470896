//@flow
import React from 'react';
import { Section } from '../../util/styles';

export default function Versions(props) {
  return (
    <Section>
      <h2>Versioning</h2>
      <p>
        The initial release of Tekmetric's API will be versioned as v1. All endpoints will include the API version as
        part of it's URL path, for example: &nbsp;
        <a href="https://sandbox.tekmetric.com/api/v1/customers">https://sandbox.tekmetric.com/api/v1/customers</a>.
        Future releases to the Tekmetric API that include breaking changes will use a different version in it's URL
        path.
      </p>
    </Section>
  );
}
