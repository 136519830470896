// @flow
import fetch from 'isomorphic-fetch';

export default function request(url: string, options?: Object = {}, headers?: Object = {}, body?: Object = null) {
  return fetch(url, { ...options, headers, body }).then(response => {
    if (!response.ok) {
      return response.json();
    }

    return response.json();
  });
}
