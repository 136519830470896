//@flow
import React, { Fragment } from 'react';
import { updatePathWithParams } from './general';
import base64 from 'base-64';
import { Chip } from '@material-ui/core';

const username = process.env.NODE_ENV === 'development' ? '6779ef20e75817b79602' : 'xcY6x2kdyW2FBqaQ';
const password = process.env.NODE_ENV === 'development' ? 'client_secret' : 'client_secret123!';
const access_token = 'Bearer dd824dda-c02c-478a-aa84-cd9fdb217c15';
let auth = `Basic ${base64.encode(username + ':' + password)}`;

export const setDefaults = endpoint => {
  let url = updatePathWithParams(
    endpoint.url,
    endpoint.parameters.filter(param => param.pathParam)
  );
  let headers = endpoint.headers.reduce((obj, header) => ({ ...obj, [header.name]: header.value }), {});
  let parameters = endpoint.parameters.reduce((obj, param) => ({ ...obj, [param.name]: param.value }), {});
  return { url, headers, parameters };
};

const paginationParams = [
  {
    name: 'sortDirection',
    type: 'String',
    value: 'ASC',
    note: 'Determine the direction you want to sort your results',
    values: [
      { label: 'ASC', value: 'ASC' },
      { label: 'DESC', value: 'DESC' }
    ]
  },
  {
    name: 'size',
    type: 'Integer',
    value: 10,
    note: <Fragment>Specify the number of results you would like returned. The maximum page size is 100. For values greater than this value, the API overrides and returns 100 records. You can use pagination to access the next records.</Fragment>
  },
  {
    name: 'page',
    type: 'Integer',
    value: 0,
    note: 'Specify the page of results you would like returned'
  }
];

const endpoints = [
  {
    name: 'Access Token',
    method: 'POST',
    url: '/api/v1/oauth/token',
    headers: [
      {
        name: 'Content-Type',
        note: 'application/x-www-form-urlencoded;charset=UTF-8',
        value: 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      {
        name: 'Authorization',
        note: 'Provide your credentials using HTTP Basic Auth. Basic client_id:client_secret, where client credentials are Base64 encoded',
        value: auth
      }
    ],
    parameters: [
      {
        name: 'grant_type',
        note: 'client_credentials',
        value: 'client_credentials'
      }
    ],
    attributes: [
      {
        name: 'scope',
        note: 'A space-separated list of Shop IDs, indicating which Shops you have access to.'
      }
    ],
    requestBody: 'grant_type=client_credentials',
    notes: 'Send your partner credentials to gain an access token. You can use this token to access shop resources.',
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">POST </span>
        <span className="__json-string__">'{url || `http://sandbox.tekmetric.com/api/v1/oauth/token`}' \</span>
        <br />

        <span>
          <span className="__json-key__">-H </span>
          <span className="__json-string__">
            "Authorization: Basic Njc3OWVmMjBlNzU4MTdiNzk2MDI6Y2xpZW50X3NlY3JldA==" \
          </span>
          <br />
        </span>
        <span>
          <span className="__json-key__">-H </span>
          <span className="__json-string__">"Content-Type: application/x-www-form-urlencoded" \</span>
          <br />
        </span>
        <span>
          <span className="__json-key__">-d </span>
          <span className="__json-string__">"grant_type=client_credentials"</span>
        </span>
      </pre>
    ),
    response: {
      access_token: '7de937e1-8574-4459-a0cc-bb4505e7803f',
      token_type: 'bearer',
      scope: '1 2'
    }
  },
  {
    name: 'Shops',
    method: 'GET',
    url: '/api/v1/shops',
    headers: [
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [],
    notes: 'Sending requests to this endpoint will return a list of shops your token has been granted access to',
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">GET </span>

        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/shops`}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}"</span>
      </pre>
    ),
    response: [
      {
        id: 1,
        name: 'Demo Shop 1',
        nickname: '',
        phone: '123-4456-7890',
        email: 'demo@tekmetric.com',
        website: 'www.demoshop1.com',
        address: {
          id: 79130,
          address1: '5704 Southwest Freeway',
          address2: '',
          city: 'Houston',
          state: 'TX',
          zip: '77057',
          streetAddress: '5704 Southwest Freeway',
          fullAddress: '5704 Southwest Freeway, Houston, TX 77057'
        },
        roCustomLabelEnabled: false
      }
    ]
  },
  {
    name: 'Shop',
    method: 'GET',
    url: '/api/v1/shops/{id}',
    headers: [
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [],
    notes:
      'Sending request to this endpoint will return details of the shop with given id if your token has required access',
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">GET </span>

        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/shops/1`}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}"</span>
      </pre>
    ),
    response: {
      id: 1,
      name: 'Demo Shop 1',
      nickname: '',
      phone: '123-4456-7890',
      email: 'demo@tekmetric.com',
      website: 'www.demoshop1.com',
      address: {
        id: 79130,
        address1: '5704 Southwest Freeway',
        address2: '',
        city: 'Houston',
        state: 'TX',
        zip: '77057',
        streetAddress: '5704 Southwest Freeway',
        fullAddress: '5704 Southwest Freeway, Houston, TX 77057'
      },
      roCustomLabelEnabled: false
    }
  },
  {
    name: 'Customers',
    method: 'GET',
    url: '/api/v1/customers',
    notes: 'Returns a list of all customers filtered by the provided search parameters.',
    headers: [
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [
      {
        name: 'shop',
        type: 'Integer',
        note: 'Search for customers by shop',
        value: 1
      },
      {
        name: 'search',
        type: 'String',
        note: 'Search for customers by their name, email, or phone number',
        value: ''
      },
      {
        name: 'okForMarketing',
        type: 'Boolean',
        note: 'Filter By customers who are ok for marketing'
      },
      {
        name: 'updatedDateStart',
        type: 'Date',
        note: 'Filter by customer updated date'
      },
      {
        name: 'updatedDateEnd',
        type: 'Date',
        note: 'Filter by customer updated date'
      },
      {
        name: 'deletedDateStart',
        type: 'Date',
        note: 'Filter by customer deleted date'
      },
      {
        name: 'deletedDateEnd',
        type: 'Date',
        note: 'Filter by customer deleted date'
      },
      {
        name: 'sort',
        type: 'String',
        values: [
          { label: 'lastName', value: 'lastName' },
          { label: 'firstName', value: 'firstName' },
          { label: 'email', value: 'email' }
        ],
        note: `Specify the property you'd like to sort on. You can specify multiple sort parameters i.e. "lastName,firstName"`
      },
      {
        name: 'customerTypeId',
        type: 'Integer',
        note: 'Search by Customer type',
        value: null,
        values: [
          { label: 'Customer', value: 1 },
          { label: 'Business', value: 2 }
        ]
      },
      ...paginationParams
    ],

    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">GET </span>

        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/customers?shop=1`}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}"</span>
      </pre>
    ),
    response: {
      content: [
        {
          id: 35680,
          firstName: 'Vince',
          lastName: 'Zulauf',
          email: 'vincezulauf@mail.test',
          phone: [
            {
              number: '111-111-1111',
              type: 'Cell',
              primary: true
            }
          ],
          customerType: {
            id: 1,
            code: 'PERSON',
            name: 'Person'
          },
          contactFirstName: null,
          contactLastName: null,
          address: {
            address1: '5103 Swift Park',
            address2: null,
            city: 'Tyreseview',
            state: 'VT',
            zip: '48824',
            streetAddress: '5103 Swift Park',
            fullAddress: '5103 Swift Park, Tyreseview, VT 48824'
          },
          shopId: 79,
          okForMarketing: true,
          createdDate: '2019-02-27T10:31:59Z',
          updatedDate: '2019-02-28T10:32:28Z',
          deletedDate: '2019-02-28T10:32:28Z'
        }
      ],
      totalPages: 458,
      last: false,
      totalElements: 4571,
      sort: [
        {
          direction: 'DESC',
          property: 'firstName',
          ignoreCase: false,
          nullHandling: 'NATIVE',
          ascending: false,
          descending: true
        }
      ],
      numberOfElements: 1,
      first: true,
      size: 1,
      number: 0
    }
  },
  {
    name: 'Customer',
    method: 'GET',
    url: '/api/v1/customers/{id}',
    notes: 'Returns a customer by ID',
    headers: [
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [
      {
        name: 'id',
        type: 'Integer',
        note: 'customerId',
        pathParam: true,
        value: 1
      }
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">GET </span>

        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/customers/35680`}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}"</span>
      </pre>
    ),
    response: {
      id: 35680,
      firstName: 'Vince',
      lastName: 'Zulauf',
      email: 'vincezulauf@mail.test',
      phone: [
        {
          number: '111-111-1111',
          type: 'Cell',
          primary: true
        }
      ],
      customerType: {
        id: 1,
        code: 'PERSON',
        name: 'Person'
      },
      contactFirstName: null,
      contactLastName: null,
      address: {
        address1: '5103 Swift Park',
        address2: null,
        city: 'Tyreseview',
        state: 'VT',
        zip: '48824',
        streetAddress: '5103 Swift Park',
        fullAddress: '5103 Swift Park, Tyreseview, VT 48824'
      },
      shopId: 79,
      okForMarketing: true,
      createdDate: '2019-02-27T10:31:59Z',
      updatedDate: '2019-02-28T10:32:28Z',
      deletedDate: null
    }
  },
  {
    name: 'Create Customer',
    method: 'POST',
    url: '/api/v1/customers',
    notes: 'Create Customer Fields',
    headers: [
      {
        name: 'Content-Type',
        note: 'application/json',
        value: 'application/json'
      },
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [],
    requestAttributes: [
      {
        name: 'shopId',
        type: 'Integer',
        note: 'Shop id',
        required: true
      },
      {
        name: 'customerTypeId',
        type: 'Integer',
        note: 'Customer type id: 1 for PERSON (default) 2 for BUSINESS'
      },
      {
        name: 'firstName',
        type: 'String',
        note: 'First name',
        required: true
      },
      {
        name: 'lastName',
        type: 'String',
        note: 'Last name'
      },
      {
        name: 'email',
        type: 'Array<String>',
        note: 'Array of email addresses'
      },
      {
        name: 'notes',
        type: 'String',
        note: 'Notes'
      },
      {
        name: 'phone (DEPRECATED)',
        type: 'Array<String>',
        note: 'Array of phone numbers. This field is deprecated; use "phones" array instead.'
      },
      {
        name: 'phones',
        type: 'Array<JSON>',
        note: 'Array of phone objects'
      },
      {
        name: 'address',
        type: 'JSON',
        note: 'Address entity with address1, address2, city, state, zip fields'
      },
      {
        name: 'okForMarketing',
        type: 'Boolean',
        note: 'Is customer OK for marketing'
      }
    ],
    attributes: [
      {
        name: 'data',
        note: 'Created customer'
      }
    ],
    request: url => (
        <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">POST </span>
        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/customers`}' \</span>
        <br/>
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Content-Type: application/json" \</span>
        <br/>
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}" \</span>
        <br/>
        <span className="__json-key__">-d </span>
        <span className="__json-string__">'&#123;</span>
        <span className="__json-string__">"shopId": 1, </span>
        <span className="__json-string__">"firstName": "John", </span>
        <span className="__json-string__">"lastName": "Smith", </span>
        <span className="__json-string__">"customerTypeId": 1, </span>
        <span className="__json-string__">"email": ["test@tekmetric.com"], </span>
        <span className="__json-string__">"phones":</span>
        <span className="__json-string__">&#91;</span>
        <span className="__json-string__">&#123;</span>
        <span className="__json-string__">"number": "111-111-1111", </span>
        <span className="__json-string__">"type": "Mobile", </span>
        <span className="__json-string__">"primary": true</span>
        <span className="__json-string__">&#125;</span>
        <span className="__json-string__">&#93;, </span>
        <span className="__json-string__">"address":</span>
        <span className="__json-string__">&#123;</span>
        <span className="__json-string__">"address1": "1981 Good Luck Rd.", </span>
        <span className="__json-string__">"address2": "Hillway Apartments", </span>
        <span className="__json-string__">"city": "Lanham", </span>
        <span className="__json-string__">"state": "Maryland", </span>
        <span className="__json-string__">"zip": "20744"</span>
        <span className="__json-string__">&#125;, </span>
        <span className="__json-string__">"notes": "notes", </span>
        <span className="__json-string__">"okForMarketing": true </span>
        <span className="__json-string__">&#125;'</span>
      </pre>
    ),
    response: {
      type: 'SUCCESS',
      message: 'Customer created',
      data: {
        id: 398340,
        firstName: 'John',
        lastName: 'Smith',
        email: 'test@tekmetric.com',
        phone: [
          {
            id: 476665,
            number: '111-111-1111',
            type: 'Mobile',
            primary: true
          }
        ],
        address: {
          id: 406619,
          address1: '1981 Good Luck Rd.',
          address2: 'Hillway Apartments',
          city: 'Lanham',
          state: 'Maryland',
          zip: '20744',
          fullAddress: '1981 Good Luck Rd. Hillway Apartments, Lanham, Maryland 20744',
          streetAddress: '1981 Good Luck Rd. Hillway Apartments'
        },
        notes: 'notes',
        customerType: {
          id: 1,
          code: 'PERSON',
          name: 'Person'
        },
        contactFirstName: null,
        contactLastName: null,
        shopId: 1,
        okForMarketing: true,
        createdDate: '2021-06-30T17:42:58.193Z',
        updatedDate: '2021-06-30T17:42:58.193Z',
        deletedDate: null,
        birthday: null
      },
      details: {}
    }
  },
  {
    name: 'Update Customer',
    method: 'PATCH',
    url: '/api/v1/customers/{id}',
    notes: 'Update Customer Fields',
    headers: [
      {
        name: 'Content-Type',
        note: 'application/json',
        value: 'application/json'
      },
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [],
    requestAttributes: [
      {
        name: 'customerTypeId',
        type: 'Integer',
        note: 'Customer type id: 1 for PERSON (default) 2 for BUSINESS'
      },
      {
        name: 'firstName',
        type: 'String',
        note: 'First name'
      },
      {
        name: 'lastName',
        type: 'String',
        note: 'Last name'
      },
      {
        name: 'email',
        type: 'Array<String>',
        note: 'Array of email addresses'
      },
      {
        name: 'notes',
        type: 'String',
        note: 'Notes'
      },
      {
        name: 'phones',
        type: 'Array<JSON>',
        note: 'Array of phone objects'
      },
      {
        name: 'address',
        type: 'JSON',
        note: 'Address entity with address1, address2, city, state, zip fields'
      },
      {
        name: 'okForMarketing',
        type: 'Boolean',
        note: 'Is customer OK for marketing'
      }
    ],
    attributes: [
      {
        name: 'data',
        note: 'Updated customer'
      }
    ],
    request: url => (
        <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">PATCH </span>
        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/customers/398340`}' \</span>
        <br/>
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Content-Type: application/json" \</span>
        <br/>
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}" \</span>
        <br/>
        <span className="__json-key__">-d </span>
        <span className="__json-string__">'&#123;</span>
        <span className="__json-string__">"customerTypeId": 2, </span>
        <span className="__json-string__">"email": ["test2@tekmetric.com"], </span>
        <span className="__json-string__">"phones": </span>
        <span className="__json-string__">&#91;</span>
        <span className="__json-string__">&#123;</span>
        <span className="__json-string__">"id": 476665, </span>
        <span className="__json-string__">"number": "222-222-2222", </span>
        <span className="__json-string__">"type": "Mobile", </span>
        <span className="__json-string__">"primary": true</span>
        <span className="__json-string__">&#125;</span>
        <span className="__json-string__">&#93;</span>
        <span className="__json-string__">&#125;'</span>
      </pre>
    ),
    response: {
      type: 'SUCCESS',
      message: 'Customer created',
      data: {
        id: 398340,
        firstName: 'John',
        lastName: 'Smith',
        email: 'test2@tekmetric.com',
        phone: [
          {
            id: 476665,
            number: '222-222-2222',
            type: 'Mobile',
            primary: true
          }
        ],
        address: {
          id: 406619,
          address1: '1981 Good Luck Rd.',
          address2: 'Hillway Apartments',
          city: 'Lanham',
          state: 'Maryland',
          zip: '20744',
          fullAddress: '1981 Good Luck Rd. Hillway Apartments, Lanham, Maryland 20744',
          streetAddress: '1981 Good Luck Rd. Hillway Apartments'
        },
        notes: 'notes',
        customerType: {
          id: 2,
          code: 'BUSINESS',
          name: 'Business'
        },
        contactFirstName: null,
        contactLastName: null,
        shopId: 1,
        okForMarketing: true,
        createdDate: '2021-06-30T17:42:58.193Z',
        updatedDate: '2021-06-30T17:42:58.193Z',
        deletedDate: null,
        birthday: null
      },
      details: {}
    }
  },
  {
    name: 'Repair Orders',
    method: 'GET',
    url: '/api/v1/repair-orders',
    notes: 'Returns a list of all repair orders filtered by the provided search parameters.',
    headers: [
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [
      {
        name: 'shop',
        type: 'Integer',
        note: 'Search for repair orders by shop',
        value: 1
      },
      {
        name: 'start',
        type: 'Date',
        note: 'Specify a start Date to filter by Repair Orders created after the entered value'
      },
      {
        name: 'end',
        type: 'Date',
        note: 'Specify a end Date to filter by Repair Orders created before the entered value'
      },
      {
        name: 'postedDateStart',
        type: 'Date',
        note: 'Filter by repair order posted date'
      },
      {
        name: 'postedDateEnd',
        type: 'Date',
        note: 'Filter by repair order posted date'
      },
      {
        name: 'updatedDateStart',
        type: 'Date',
        note: 'Filter by repair order updated date'
      },
      {
        name: 'updatedDateEnd',
        type: 'Date',
        note: 'Filter by repair order updated date'
      },
      {
        name: 'deletedDateStart',
        type: 'Date',
        note: 'Filter by repair order deleted date'
      },
      {
        name: 'deletedDateEnd',
        type: 'Date',
        note: 'Filter by repair order deleted date'
      },
      {
        name: 'repairOrderNumber',
        type: 'Integer',
        note: 'Specify a specific RO# you want returned'
      },
      {
        name: 'repairOrderStatusId',
        type: 'Array<Integer>',
        note: 'Specify a status if you want to filter results to a specific status',
        isArray: true,
        values: [
          { label: 'Estimate', value: 1 },
          { label: 'Work-in-Progress', value: 2 },
          { label: 'Complete', value: 3 },
          { label: 'Saved for Later', value: 4 },
          { label: 'Posted', value: 5 },
          { label: 'Accounts Receivable', value: 6 },
          { label: 'Deleted', value: 7 }
        ]
      },
      {
        name: 'customerId',
        type: 'Integer',
        note: 'Specify this value to get repairs for a specific customer'
      },
      {
        name: 'vehicleId',
        type: 'Integer',
        note: 'Specify this value to get repairs for a specific vehicle'
      },
      {
        name: 'search',
        type: 'String',
        note: 'Search for repair orders by RO #, customer name, and vehicle info'
      },
      {
        name: 'sort',
        type: 'String',
        values: [
          { label: 'createdDate', value: 'createdDate' },
          { label: 'repairOrderNumber', value: 'repairOrderNumber' },
          { label: 'customer.firstName', value: 'customer.firstName' },
          { label: 'customer.lastName', value: 'customer.lastName' }
        ],
        note: "Specify the property you'd like to sort on"
      },
      ...paginationParams
    ],
    attributes: [
      {
        name: 'repairOrderNumber',
        note: 'Repair Order identifier per Shop'
      },
      {
        name: 'laborSales',
        note: 'Total labor sales in cents. Taxes and discounts not included.'
      },
      {
        name: 'partsSales',
        note: 'Total parts sales in cents. Taxes and discounts not included.'
      },
      {
        name: 'subletSales',
        note: 'Total sublet sales in cents. Taxes and discounts not included.'
      },
      {
        name: 'feeTotal',
        note: 'Total fees in cents. Taxes and discounts not included.'
      },
      {
        name: 'discountTotal',
        note: 'Total discounts in cents.'
      },
      {
        name: 'amountPaid',
        note: 'Total amount paid by customer.'
      }
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">GET </span>

        <span className="__json-string__">
          '{url || `https://sandbox.tekmetric.com/api/v1/repair-orders?shop=1`}' \
        </span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}"</span>
      </pre>
    ),
    response: {
      content: [
        {
          id: 869586,
          repairOrderNumber: 43,
          shopId: 1,
          repairOrderStatus: {
            id: 2,
            code: 'WORKINPROGRESS',
            name: 'Work In Progress'
          },
          repairOrderLabel: {
            id: 12,
            code: 'WORKNOTSTRTD',
            name: 'Work Not Started',
            status: {
              id: 2,
              code: 'WORKINPROGRESS',
              name: 'Work In Progress'
            }
          },
          repairOrderCustomLabel: {
            name: 'Wash Bay'
          },
          color: '#1786E8',
          appointmentStartTime: '2019-02-28T10:31:59Z',
          customerId: 361186,
          technicianId: null,
          serviceWriterId: 616,
          vehicleId: 516457,
          milesIn: null,
          milesOut: null,
          keytag: null,
          completedDate: '2019-02-28T10:31:59Z',
          postedDate: '2019-02-28T10:32:28Z',
          laborSales: 13000,
          partsSales: 25997,
          subletSales: 20000,
          discountTotal: 11650,
          feeTotal: 8800,
          taxes: 1960,
          amountPaid: 0,
          totalSales: 58107,
          jobs: [
            {
              id: 1084138,
              repairOrderId: 869586,
              vehicleId: 516457,
              customerId: 361186,
              name: 'Diagnostic Inspection',
              authorized: true,
              authorizedDate: '2019-02-25T16:12:17',
              selected: true,
              technicianId: 100,
              note: 'Code 2343 - will need airbag replacement',
              partsTotal: 25997,
              laborTotal: 13000,
              discountTotal: 1650,
              feeTotal: 4900,
              subtotal: 42247,
              createdDate: '2019-02-27T10:31:59Z',
              updatedDate: '2019-02-28T10:32:28Z',
              labor: [
                {
                  id: 2563821,
                  name: 'Diagnostic Inspection',
                  rate: 13000,
                  hours: 1,
                  complete: false
                }
              ],
              parts: [
                {
                  id: 2735929,
                  quantity: 1,
                  brand: 'AutoCraft Gold',
                  name: 'Battery',
                  partNumber: '#35-2',
                  description: 'Group size 35, 640 CCA',
                  cost: 15999,
                  retail: 23997,
                  model: null,
                  width: null,
                  ratio: null,
                  diameter: null,
                  constructionType: null,
                  loadIndex: null,
                  speedRating: null,
                  partType: {
                    id: 1,
                    code: 'PART',
                    name: 'Part'
                  }
                },
                {
                  id: 2735928,
                  quantity: 1,
                  brand: 'Cooper',
                  name: null,
                  partNumber: null,
                  description: null,
                  cost: 1000,
                  retail: 2000,
                  model: 'Tire',
                  width: '105',
                  ratio: 45,
                  diameter: 17,
                  constructionType: '',
                  loadIndex: '',
                  speedRating: '',
                  partType: {
                    id: 2,
                    code: 'TIRE',
                    name: 'Tire'
                  },
                  dotNumbers: ['12345678', 'ASDF5678']
                }
              ],
              fees: [
                {
                  id: 685613,
                  name: 'Percentage Job Fee',
                  total: 3900
                },
                {
                  id: 685612,
                  name: 'Fixed Job Fee',
                  total: 1000
                }
              ],
              discounts: [
                {
                  id: 125750,
                  name: 'Percentage Job Discount',
                  total: 650
                },
                {
                  id: 125748,
                  name: 'Fixed Job Discount',
                  total: 1000
                }
              ],
              laborHours: 0.2,
              loggedHours: 0.67
            }
          ],
          sublets: [
            {
              id: 48802,
              name: 'Custom Sublet',
              vendor: {
                id: 2257,
                name: 'NAPA Auto Parts',
                nickname: 'One Broadway CAMBRIDGE, MA 02142-1187',
                website: null,
                phone: null
              },
              authorized: null,
              authorizedDate: null,
              selected: true,
              note: null,
              items: [
                {
                  id: 48523,
                  name: 'Sublet Line Item',
                  cost: 10000,
                  price: 20000,
                  complete: false
                }
              ],
              price: 20000,
              cost: 10000
            }
          ],
          fees: [
            {
              id: 685614,
              name: 'Shop Supplies',
              total: 3900
            }
          ],
          discounts: [
            {
              id: 125749,
              name: 'RO Discount',
              total: 10000
            }
          ],
          customerConcerns: [
            {
              id: 8241,
              concern: 'NOISE WHEN TURNING LEFT ',
              techComment: null
            },
            {
              id: 8242,
              concern: 'CHECK TPMS SENSOR',
              techComment: null
            }
          ],
          createdDate: '2019-02-27T10:31:59Z',
          updatedDate: '2019-02-28T10:32:28Z',
          deletedDate: null
        }
      ],
      pageable: {
        sort: {
          unsorted: false,
          sorted: true,
          empty: false
        },
        offset: 0,
        pageSize: 10,
        pageNumber: 0,
        paged: true,
        unpaged: false
      },
      totalPages: 1,
      totalElements: 1,
      last: true,
      size: 10,
      number: 0,
      first: true,
      sort: {
        unsorted: false,
        sorted: true,
        empty: false
      },
      numberOfElements: 1,
      empty: false
    }
  },
  {
    name: 'Repair Order',
    method: 'GET',
    url: '/api/v1/repair-orders/{id}',
    notes: 'Returns a Repair Order by ID',
    headers: [
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [
      {
        name: 'id',
        type: 'Integer',
        note: 'repairOrderId',
        pathParam: true,
        value: 1
      }
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">GET </span>

        <span className="__json-string__">
          '{url || `https://sandbox.tekmetric.com/api/v1/repair-orders/869586`}' \
        </span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}"</span>
      </pre>
    ),
    response: {
      id: 869586,
      repairOrderNumber: 43,
      shopId: 1,
      repairOrderStatus: {
        id: 2,
        code: 'WORKINPROGRESS',
        name: 'Work In Progress'
      },
      repairOrderLabel: {
        id: 12,
        code: 'WORKNOTSTRTD',
        name: 'Work Not Started',
        status: {
          id: 2,
          code: 'WORKINPROGRESS',
          name: 'Work In Progress'
        }
      },
      repairOrderCustomLabel: {
        name: 'Wash Bay'
      },
      color: '#1786E8',
      appointmentStartTime: '2019-02-28T10:31:59Z',
      customerId: 361186,
      technicianId: null,
      serviceWriterId: 616,
      vehicleId: 516457,
      milesIn: null,
      milesOut: null,
      keytag: null,
      completedDate: '2019-02-28T10:31:59Z',
      postedDate: '2019-02-28T10:32:28Z',
      laborSales: 13000,
      partsSales: 25997,
      subletSales: 20000,
      discountTotal: 11650,
      feeTotal: 8800,
      taxes: 1960,
      amountPaid: 0,
      totalSales: 58107,
      jobs: [
        {
          id: 1084138,
          repairOrderId: 869586,
          vehicleId: 516457,
          customerId: 361186,
          name: 'Diagnostic Inspection',
          authorized: true,
          authorizedDate: '2019-02-25T16:12:17',
          selected: true,
          technicianId: 100,
          note: 'Code 2343 - will need airbag replacement',
          partsTotal: 25997,
          laborTotal: 13000,
          discountTotal: 1650,
          feeTotal: 4900,
          subtotal: 42247,
          createdDate: '2019-02-27T10:31:59Z',
          updatedDate: '2019-02-28T10:32:28Z',
          labor: [
            {
              id: 2563821,
              name: 'Diagnostic Inspection',
              rate: 13000,
              hours: 1,
              complete: false
            }
          ],
          parts: [
            {
              id: 2735929,
              quantity: 1,
              brand: 'AutoCraft Gold',
              name: 'Battery',
              partNumber: '#35-2',
              description: 'Group size 35, 640 CCA',
              cost: 15999,
              retail: 23997,
              model: null,
              width: null,
              ratio: null,
              diameter: null,
              constructionType: null,
              loadIndex: null,
              speedRating: null,
              partType: {
                id: 1,
                code: 'PART',
                name: 'Part'
              }
            },
            {
              id: 2735928,
              quantity: 1,
              brand: 'Cooper',
              name: null,
              partNumber: null,
              description: null,
              cost: 1000,
              retail: 2000,
              model: 'Tire',
              width: '105',
              ratio: 45,
              diameter: 17,
              constructionType: '',
              loadIndex: '',
              speedRating: '',
              partType: {
                id: 2,
                code: 'TIRE',
                name: 'Tire'
              },
              dotNumbers: ['12345678', 'ASDF5678']
            }
          ],
          fees: [
            {
              id: 685613,
              name: 'Percentage Job Fee',
              total: 3900
            },
            {
              id: 685612,
              name: 'Fixed Job Fee',
              total: 1000
            }
          ],
          discounts: [
            {
              id: 125750,
              name: 'Percentage Job Discount',
              total: 650
            },
            {
              id: 125748,
              name: 'Fixed Job Discount',
              total: 1000
            }
          ],
          laborHours: 0.2,
          loggedHours: 0.67
        }
      ],
      sublets: [
        {
          id: 48802,
          name: 'Custom Sublet',
          vendor: {
            id: 2257,
            name: 'NAPA Auto Parts',
            nickname: 'One Broadway CAMBRIDGE, MA 02142-1187',
            website: null,
            phone: null
          },
          authorized: null,
          authorizedDate: null,
          selected: true,
          note: null,
          items: [
            {
              id: 48523,
              name: 'Sublet Line Item',
              cost: 10000,
              price: 20000,
              complete: false
            }
          ],
          price: 20000,
          cost: 10000
        }
      ],
      fees: [
        {
          id: 685614,
          name: 'Shop Supplies',
          total: 3900
        }
      ],
      discounts: [
        {
          id: 125749,
          name: 'RO Discount',
          total: 10000
        }
      ],
      customerConcerns: [
        {
          id: 8241,
          concern: 'NOISE WHEN TURNING LEFT ',
          techComment: null
        },
        {
          id: 8242,
          concern: 'CHECK TPMS SENSOR',
          techComment: null
        }
      ],
      createdDate: '2019-02-27T10:31:59Z',
      updatedDate: '2019-02-28T10:32:28Z',
      deletedDate: null
    }
  },
  {
    name: 'Update Repair Order',
    method: 'PATCH',
    url: '/api/v1/repair-orders/{id}',
    notes: 'Update Repair Order Fields',
    headers: [
      {
        name: 'Content-Type',
        note: 'application/json',
        value: 'application/json'
      },
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [],
    requestAttributes: [
      {
        name: 'keyTag',
        type: 'String',
        note: 'KeyTag value on Repair Order'
      },
      {
        name: 'milesIn',
        type: 'Integer',
        note: 'Mileage in on Repair Order'
      },
      {
        name: 'milesOut',
        type: 'Integer',
        note: 'Mileage out on Repair Order'
      },
      {
        name: 'technicianId',
        type: 'Integer',
        note: 'Set default Technician on Repair Order by employeeId'
      },
      {
        name: 'serviceWriterId',
        type: 'Integer',
        note: 'Set Service Writer on Repair Order by employeeId'
      },
      {
        name: 'customerTimeOut',
        type: 'DateTime',
        note: 'Set Promise Time on Repair Order'
      }
    ],
    attributes: [
      {
        name: 'data',
        note: 'Saved Repair'
      }
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">PATCH </span>
        <span className="__json-string__">
          '{url || `https://sandbox.tekmetric.com/api/v1/repair-orders/1104636`}' \
        </span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Content-Type: application/json" \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}" \</span>
        <br />
        <span className="__json-key__">-d </span>
        <span className="__json-string__">'&#123;</span>
        <span className="__json-string__">"milesIn": 110463</span>
        <span className="__json-string__">&#125;'</span>
      </pre>
    ),
    response: {
      type: 'SUCCESS',
      message: 'Repair Order Saved',
      data: {}
    }
  },
  {
    name: 'Jobs',
    method: 'GET',
    url: '/api/v1/jobs',
    notes: 'Returns a list of all jobs filtered by the provided search parameters.',
    headers: [
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [
      {
        name: 'shop',
        type: 'Integer',
        note: 'Filter by shop ID',
        value: 1
      },
      {
        name: 'vehicleId',
        type: 'Integer',
        note: 'Filter by vehicle ID'
      },
      {
        name: 'repairOrderId',
        type: 'Integer',
        note: 'Filter by repair order ID'
      },
      {
        name: 'customerId',
        type: 'Integer',
        note: 'Filter by customer ID'
      },
      {
        name: 'authorized',
        type: 'Boolean',
        note: 'Filter by authorized jobs'
      },
      {
        name: 'authorizedDateStart',
        type: 'Date',
        note: 'Filter by job authorization date'
      },
      {
        name: 'authorizedDateEnd',
        type: 'Date',
        note: 'Filter by job authorization date'
      },
      {
        name: 'updatedDateStart',
        type: 'Date',
        note: 'Filter by job updated date'
      },
      {
        name: 'updatedDateEnd',
        type: 'Date',
        note: 'Filter by job updated date'
      },
      {
        name: 'repairOrderStatusId',
        type: 'Array<Integer>',
        note: 'Filter results by repair order status',
        isArray: true,
        values: [
          { label: 'Estimate', value: 1 },
          { label: 'Work-in-Progress', value: 2 },
          { label: 'Complete', value: 3 },
          { label: 'Saved for Later', value: 4 },
          { label: 'Posted', value: 5 },
          { label: 'Accounts Receivable', value: 6 }
        ]
      },
      {
        name: 'sort',
        type: 'String',
        values: [{ label: 'authorizedDate', value: 'authorizedDate' }],
        note: "Specify the property you'd like to sort on"
      },
      ...paginationParams
    ],
    attributes: [
      {
        name: 'authorized',
        note: 'Boolean indicating if job was approved or declined by customer'
      },
      {
        name: 'authorizedDate',
        note: 'Date customer approved or declined the job'
      },
      {
        name: 'selected',
        note: 'Boolean indicating if job was presented to customer for authorization'
      },
      {
        name: 'partsTotal',
        note: 'Total parts sales in cents. Taxes and discounts not included.'
      },
      {
        name: 'laborTotal',
        note: 'Total labor sales in cents. Taxes and discounts not included.'
      },
      {
        name: 'discountTotal',
        note: 'Total discounts in cents.'
      },
      {
        name: 'feeTotal',
        note: 'Total fees in cents. Taxes and discounts not included.'
      },
      {
        name: 'archived',
        note: 'Boolean indicating if job was declined and marked as archived (non-marketable)'
      }
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">GET </span>

        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/jobs?shop=1`}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}"</span>
      </pre>
    ),
    response: {
      content: [
        {
          id: 1084138,
          repairOrderId: 869586,
          vehicleId: 516457,
          customerId: 361186,
          name: 'Diagnostic Inspection',
          authorized: true,
          authorizedDate: '2019-02-25T16:12:17',
          selected: true,
          technicianId: 100,
          note: 'Code 2343 - will need airbag replacement',
          jobCategoryName: 'Heating & Air Conditioning',
          partsTotal: 25997,
          laborTotal: 13000,
          discountTotal: 1650,
          feeTotal: 4900,
          subtotal: 42247,
          archived: false,
          createdDate: '2019-02-27T10:31:59Z',
          updatedDate: '2019-02-28T10:32:28Z',
          completedDate: '2019-02-28T11:35:28Z',
          labor: [
            {
              id: 2563821,
              name: 'Diagnostic Inspection',
              rate: 13000,
              hours: 1,
              complete: false
            }
          ],
          parts: [
            {
              id: 2735929,
              quantity: 1,
              brand: 'AutoCraft Gold',
              name: 'Battery',
              partNumber: '#35-2',
              description: 'Group size 35, 640 CCA',
              cost: 15999,
              retail: 23997,
              model: null,
              width: null,
              ratio: null,
              diameter: null,
              constructionType: null,
              loadIndex: null,
              speedRating: null,
              partType: {
                id: 1,
                code: 'PART',
                name: 'Part'
              }
            },
            {
              id: 2735928,
              quantity: 1,
              brand: 'Cooper',
              name: null,
              partNumber: null,
              description: null,
              cost: 1000,
              retail: 2000,
              model: 'Tire',
              width: '105',
              ratio: 45,
              diameter: 17,
              constructionType: '',
              loadIndex: '',
              speedRating: '',
              partType: {
                id: 2,
                code: 'TIRE',
                name: 'Tire'
              },
              dotNumbers: ['12345678', 'ASDF5678']
            }
          ],
          fees: [
            {
              id: 685613,
              name: 'Percentage Job Fee',
              total: 3900
            },
            {
              id: 685612,
              name: 'Fixed Job Fee',
              total: 1000
            }
          ],
          discounts: [
            {
              id: 125750,
              name: 'Percentage Job Discount',
              total: 650
            },
            {
              id: 125748,
              name: 'Fixed Job Discount',
              total: 1000
            }
          ],
          laborHours: 0.2,
          loggedHours: 0.67,
          sort: 1
        }
      ],
      pageable: {
        sort: {
          unsorted: false,
          sorted: true,
          empty: false
        },
        offset: 0,
        pageSize: 10,
        pageNumber: 0,
        paged: true,
        unpaged: false
      },
      totalPages: 1,
      totalElements: 1,
      last: true,
      size: 10,
      number: 0,
      first: true,
      sort: {
        unsorted: false,
        sorted: true,
        empty: false
      },
      numberOfElements: 1,
      empty: false
    }
  },
  {
    name: 'Job',
    method: 'GET',
    url: '/api/v1/jobs/{id}',
    notes: 'Returns a Job by ID',
    headers: [
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [
      {
        name: 'id',
        type: 'Integer',
        note: 'job ID',
        pathParam: true,
        value: 1
      }
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">GET </span>

        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/jobs/1084138`}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}"</span>
      </pre>
    ),
    response: {
      id: 1084138,
      repairOrderId: 869586,
      vehicleId: 516457,
      customerId: 361186,
      name: 'Diagnostic Inspection',
      authorized: true,
      authorizedDate: '2019-02-25T16:12:17',
      selected: true,
      technicianId: 100,
      note: 'Code 2343 - will need airbag replacement',
      jobCategoryName: 'Heating & Air Conditioning',
      partsTotal: 25997,
      laborTotal: 13000,
      discountTotal: 1650,
      feeTotal: 4900,
      subtotal: 42247,
      archived: false,
      createdDate: '2019-02-27T10:31:59Z',
      updatedDate: '2019-02-28T10:32:28Z',
      completedDate: '2019-02-28T11:35:28Z',
      labor: [
        {
          id: 2563821,
          name: 'Diagnostic Inspection',
          rate: 13000,
          hours: 1,
          complete: false
        }
      ],
      parts: [
        {
          id: 2735929,
          quantity: 1,
          brand: 'AutoCraft Gold',
          name: 'Battery',
          partNumber: '#35-2',
          description: 'Group size 35, 640 CCA',
          cost: 15999,
          retail: 23997,
          model: null,
          width: null,
          ratio: null,
          diameter: null,
          constructionType: null,
          loadIndex: null,
          speedRating: null,
          partType: {
            id: 1,
            code: 'PART',
            name: 'Part'
          }
        },
        {
          id: 2735928,
          quantity: 1,
          brand: 'Cooper',
          name: null,
          partNumber: null,
          description: null,
          cost: 1000,
          retail: 2000,
          model: 'Tire',
          width: '105',
          ratio: 45,
          diameter: 17,
          constructionType: '',
          loadIndex: '',
          speedRating: '',
          partType: {
            id: 2,
            code: 'TIRE',
            name: 'Tire'
          },
          dotNumbers: ['12345678', 'ASDF5678']
        }
      ],
      fees: [
        {
          id: 685613,
          name: 'Percentage Job Fee',
          total: 3900
        },
        {
          id: 685612,
          name: 'Fixed Job Fee',
          total: 1000
        }
      ],
      discounts: [
        {
          id: 125750,
          name: 'Percentage Job Discount',
          total: 650
        },
        {
          id: 125748,
          name: 'Fixed Job Discount',
          total: 1000
        }
      ],
      laborHours: 0.2,
      loggedHours: 0.67,
      sort: 1
    }
  },
  {
    name: 'Update Job',
    method: 'PATCH',
    url: '/api/v1/jobs/{id}',
    notes: 'Update Job Fields',
    headers: [
      {
        name: 'Content-Type',
        note: 'application/json',
        value: 'application/json'
      },
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [],
    requestAttributes: [
      {
        name: 'completed',
        type: 'Boolean',
        note: 'Indicate if a jobs has been completed'
      },
      {
        name: 'name',
        type: 'String',
        note: 'Name of the job'
      },
      {
        name: 'note',
        type: 'String',
        note: 'Notes relevant to the job'
      },
      {
        name: 'technicianId',
        type: 'Integer',
        note: 'Set technician on job by employeeId'
      },
      {
        name: 'loggedHours',
        type: 'Decimal',
        note: 'Hours logged on job by employee (in hours)'
      }
    ],
    attributes: [
      {
        name: 'data',
        note: 'Saved Job'
      }
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">PATCH </span>
        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/jobs/1197955`}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Content-Type: application/json" \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}" \</span>
        <br />
        <span className="__json-key__">-d </span>
        <span className="__json-string__">'&#123;</span>
        <span className="__json-string__">"name": "Brake Job"</span>
        <span className="__json-string__">&#125;'</span>
      </pre>
    ),
    response: {
      type: 'SUCCESS',
      message: 'Job Saved',
      data: {
        id: 1084138,
        repairOrderId: 869586,
        vehicleId: 516457,
        customerId: 361186,
        name: 'Diagnostic Inspection',
        authorized: true,
        authorizedDate: '2019-02-25T16:12:17',
        selected: true,
        technicianId: 100,
        note: 'Code 2343 - will need airbag replacement',
        partsTotal: 25997,
        laborTotal: 13000,
        discountTotal: 1650,
        feeTotal: 4900,
        subtotal: 42247,
        createdDate: '2019-02-27T10:31:59Z',
        updatedDate: '2019-02-28T10:32:28Z',
        labor: [],
        parts: [],
        fees: [],
        discounts: []
      }
    }
  },
  {
    name: 'Canned Jobs',
    method: 'GET',
    url: '/api/v1/canned-jobs',
    notes: 'Returns a list of all canned jobs filtered by the provided search parameters.',
    headers: [
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [
      {
        name: 'shop',
        type: 'Integer',
        note: 'Filter by shop ID',
        value: 1
      },
      {
        name: 'search',
        type: 'String',
        note: 'Filter by job name',
        value: 1
      },
      {
        name: 'categories',
        type: 'Array<String>',
        note: 'Filter results by job category codes',
        isArray: true,
        values: [
          { label: 'Filter jobs with no categories', value: 'Unassigned' },
          { label: 'Filter with job category code', value: 'Category Code' }
        ]
      },
      {
        name: 'rates',
        type: 'Array<String>',
        note: 'Filter results by labor rates',
        isArray: true,
        values: [
          { label: 'Filter jobs with no labor', value: 'None' },
          { label: 'Filter with labor rate', value: 'Labor rate in cents, i.e. 7250' }
        ]
      },
      {
        name: 'sort',
        type: 'String',
        values: [{ label: 'jobCategory', value: 'jobCategory' }],
        note: "Specify the property you'd like to sort on"
      },
      ...paginationParams
    ],
    attributes: [
      {
        name: 'id',
        note: 'Canned job id'
      },
      {
        name: 'name',
        note: 'Canned job name'
      },
      {
        name: 'note',
        note: 'Notes on canned job'
      },
      {
        name: 'jobTemplateType',
        note: 'Canned job template type'
      },
      {
        name: 'jobCategoryCode',
        note: 'Canned job category code'
      },
      {
        name: 'totalCost',
        note: 'Total cost of job in cents'
      },
      {
        name: 'laborRates',
        note: 'List of unique labor rates for job'
      },
      {
        name: 'labor',
        note: 'List of labor'
      },
      {
        name: 'parts',
        note: 'List of parts'
      },
      {
        name: 'discounts',
        note: 'List of discounts'
      },
      {
        name: 'fees',
        note: 'List of fees'
      }
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">GET </span>

        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/canned-jobs?shop=1`}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}"</span>
      </pre>
    ),
    response: {
      content: [
        {
          id: 609280,
          name: 'TIRE PATCH',
          note: null,
          totalCost: 3577,
          jobTemplateType: null,
          jobCategoryCode: null,
          laborRates: [7331, 12715],
          labor: [
            {
              id: 1482155,
              name: 'PATCH',
              rate: 7331,
              hours: 0.3,
              complete: false
            },
            {
              id: 1482157,
              name: 'MOUNT AND BALANCE',
              rate: 12715,
              hours: 0,
              complete: false
            }
          ],
          parts: [
            {
              id: 1484852,
              quantity: 1,
              brand: null,
              name: 'PATCH PLUG KIT',
              partNumber: 'TIRE PPL 710',
              description: null,
              cost: 100,
              retail: 1300,
              model: null,
              width: null,
              ratio: null,
              diameter: null,
              constructionType: null,
              loadIndex: null,
              speedRating: null,
              partType: {
                id: 1,
                code: 'PART',
                name: 'Part'
              },
              partStatus: null
            }
          ],
          discounts: [
            {
              id: 125748,
              name: 'Fixed Job Discount',
              total: 1000
            }
          ],
          fees: [
            {
              id: 685612,
              name: 'Fixed Job Fee',
              total: 1000
            }
          ],
          packagePrice: false
        }
      ],
      pageable: {
        sort: {
          sorted: false,
          unsorted: true,
          empty: true
        },
        pageNumber: 0,
        offset: 0,
        pageSize: 10,
        paged: true,
        unpaged: false
      },
      totalElements: 1,
      totalPages: 1,
      last: true,
      first: true,
      size: 10,
      number: 0,
      sort: {
        sorted: false,
        unsorted: true,
        empty: true
      },
      numberOfElements: 1,
      empty: false
    }
  },
  {
    name: 'Add Canned Jobs To Repair Order',
    method: 'POST',
    url: '/api/v1/repair-orders/{id}/canned-jobs',
    notes: 'Adds given canned jobs to repair order',
    headers: [
      {
        name: 'Content-Type',
        note: 'application/json',
        value: 'application/json'
      },
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [
      {
        name: 'id',
        type: 'Integer',
        note: 'repairOrderId',
        pathParam: true,
        value: 1
      }
    ],
    requestAttributes: [
      {
        name: '',
        type: '',
        note: 'List of canned job ids',
        required: true
      }
    ],
    attributes: [
      {
        name: 'data',
        note: 'List of created job ids'
      }
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">POST </span>
        <span className="__json-string__">
          '{url || `https://sandbox.tekmetric.com/api/v1/repair-orders/1/canned-jobs`}' \
        </span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}" \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Content-Type: application/json" \</span>
        <br />
        <span className="__json-key__">--data-raw </span>
        <span className="__json-string__">'[609801]'</span>
      </pre>
    ),
    response: {
      type: 'SUCCESS',
      message: 'Job(s) saved',
      data: {
        jobs: [1089313]
      }
    }
  },
  {
    name: (
      <>
        Job Clock
        <Chip label="New" style={{ marginLeft: 5 }} />
      </>
    ),
    method: 'PUT',
    url: '/api/v1/jobs/{id}/job-clock',
    notes: 'Updates job clock logged time of a technician',
    headers: [
      {
        name: 'Content-Type',
        note: 'application/json',
        value: 'application/json'
      },
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">PUT </span>

        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/jobs/1/job-clock`}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}"</span>
      </pre>
    ),
    parameters: [
      {
        name: 'id',
        type: 'Integer',
        note: 'Job ID'
      }
    ],
    requestAttributes: [
      {
        name: 'technicianId',
        type: 'Integer',
        note: 'EmployeeId of the technician'
      },
      {
        name: 'loggedHours',
        type: 'Decimal',
        note: 'Hours logged on job by employee (in hours)'
      }
    ],
    attributes: [
      {
        name: 'data',
        note: 'Updated job clock'
      }
    ],
    response: {
      content: [
        {
          id: 1,
          job: {},
          technician: {},
          loggedTime: 1,
          loggedTimeEdited: true,
          lastStartTime: '2023-02-27T10:31:59Z',
          serverTime: '2023-02-27T10:31:59Z'
        }
      ]
    }
  },
  {
    name: (
      <>
        Update Labor
        <Chip label="New" style={{ marginLeft: 5 }} />
      </>
    ),
    method: 'PATCH',
    url: '/api/v1/labor/{id}',
    notes: 'Update technician of the labor',
    headers: [
      {
        name: 'Content-Type',
        note: 'application/json',
        value: 'application/json'
      },
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">POST </span>

        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/labor/1`}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}"</span>
      </pre>
    ),
    parameters: [
      {
        name: 'id',
        type: 'Integer',
        note: 'Labor ID'
      }
    ],
    requestAttributes: [
      {
        name: 'technicianId',
        type: 'Integer',
        note: 'EmployeeId of the technician'
      }
    ],
    attributes: [
      {
        name: 'data',
        note: 'Updated labor'
      }
    ],
    response: {
      content: {
        id: 1,
        name: 'string',
        rate: 1,
        hours: 1,
        technicianId: 1,
        complete: false
      }
    }
  },

  {
    name: 'Vehicles',
    method: 'GET',
    url: '/api/v1/vehicles',
    notes: 'Returns a list of all vehicles filtered by the provided search parameters',
    headers: [
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [
      {
        name: 'shop',
        type: 'Integer',
        note: 'Search for vehicles by shop',
        value: 1
      },
      {
        name: 'customerId',
        type: 'Integer',
        note: 'Specify a customerId to receive vehicles for specific customer'
      },
      {
        name: 'search',
        type: 'String',
        note: 'Search for vehicles year, make or model'
      },
      {
        name: 'updatedDateStart',
        type: 'Date',
        note: 'Filter by vehicle updated date'
      },
      {
        name: 'updatedDateEnd',
        type: 'Date',
        note: 'Filter by vehicle updated date'
      },
      {
        name: 'deletedDateStart',
        type: 'Date',
        note: 'Filter by vehicle deleted date'
      },
      {
        name: 'deletedDateEnd',
        type: 'Date',
        note: 'Filter by vehicle deleted date'
      },
      {
        name: 'sort',
        type: 'String',
        note: "Specify the property you'd like to sort on"
      },
      ...paginationParams
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">GET </span>

        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/vehicles?shop=1`}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}"</span>
      </pre>
    ),
    response: {
      content: [
        {
          id: 359093,
          customerId: 258819,
          year: 2006,
          make: 'Ford',
          model: 'Escape',
          subModel: 'XLT',
          engine: '3.0L V6 (1) GAS FI',
          color: 'blue',
          licensePlate: 'tag-no',
          state: 'TX',
          vin: '',
          driveType: 'AWD',
          transmission: 'Automatic',
          bodyType: 'Wagon',
          notes: null,
          unitNumber: null,
          createdDate: '2019-02-27T10:31:59Z',
          updatedDate: '2019-02-28T10:32:28Z',
          deletedDate: '2019-03-28T10:32:28Z'
        },
        {
          id: 375446,
          customerId: 258819,
          year: 2008,
          make: 'Lexus',
          model: 'GX470',
          subModel: 'Base',
          engine: '4.7L V8 (2UZ-FE) GAS FI',
          color: null,
          licensePlate: 'tag-123',
          state: 'VA',
          vin: null,
          driveType: 'AWD',
          transmission: 'Automatic CVT',
          bodyType: 'Sport Utility',
          notes: null,
          unitNumber: null,
          createdDate: '2019-02-27T10:31:59Z',
          updatedDate: '2019-02-28T10:32:28Z',
          deletedDate: '2019-03-28T10:32:28Z'
        },
        {
          id: 517955,
          customerId: 258819,
          year: 2015,
          make: 'Ford',
          model: 'Fusion',
          subModel: 'SE',
          engine: '2.5L L4 (7) GAS FI',
          color: null,
          licensePlate: 'tag',
          state: 'TX',
          vin: 'sample-vin',
          driveType: 'FWD',
          transmission: 'Manual',
          bodyType: 'Sedan',
          notes: null,
          unitNumber: null,
          createdDate: '2019-02-27T10:31:59Z',
          updatedDate: '2019-02-28T10:32:28Z',
          deletedDate: '2019-03-28T10:32:28Z'
        }
      ],
      pageable: {
        sort: {
          unsorted: false,
          sorted: true,
          empty: false
        },
        offset: 0,
        pageSize: 10,
        pageNumber: 0,
        paged: true,
        unpaged: false
      },
      totalPages: 1,
      totalElements: 3,
      last: true,
      size: 10,
      number: 0,
      first: true,
      sort: {
        unsorted: false,
        sorted: true,
        empty: false
      },
      numberOfElements: 3,
      empty: false
    }
  },
  {
    name: 'Vehicle',
    method: 'GET',
    url: '/api/v1/vehicles/{id}',
    notes: 'Returns a Vehicle by ID',
    headers: [
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [
      {
        name: 'id',
        type: 'Integer',
        note: 'vehicleId',
        pathParam: true,
        value: 1
      }
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">GET </span>

        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/vehicles/1`}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}"</span>
      </pre>
    ),
    response: {
      id: 1,
      customerId: 258819,
      year: 2006,
      make: 'Ford',
      model: 'Escape',
      subModel: 'XLT',
      engine: '3.0L V6 (1) GAS FI',
      color: 'blue',
      licensePlate: 'tag-no',
      state: 'TX',
      vin: '',
      driveType: 'AWD',
      transmission: 'Automatic',
      bodyType: 'Wagon',
      notes: null,
      unitNumber: null,
      createdDate: '2019-02-27T10:31:59Z',
      updatedDate: '2019-02-28T10:32:28Z',
      deletedDate: '2019-03-28T10:32:28Z'
    }
  },
  {
    name: 'Create Vehicle',
    method: 'POST',
    url: '/api/v1/vehicles',
    notes: 'Create Vehicle Fields',
    headers: [
      {
        name: 'Content-Type',
        note: 'application/json',
        value: 'application/json'
      },
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [],
    requestAttributes: [
      {
        name: 'customerId',
        type: 'Integer',
        note: 'Customer id',
        required: true
      },
      {
        name: 'year',
        type: 'Integer',
        note: 'Year of Vehicle',
        required: true
      },
      {
        name: 'make',
        type: 'String',
        note: 'Make of Vehicle',
        required: true
      },
      {
        name: 'model',
        type: 'String',
        note: 'Model of Vehicle',
        required: true
      },
      {
        name: 'subModel',
        type: 'String',
        note: 'SubModel of Vehicle'
      },
      {
        name: 'engine',
        type: 'String',
        note: 'Engine of Vehicle'
      },
      {
        name: 'color',
        type: 'String',
        note: 'Color of Vehicle'
      },
      {
        name: 'licensePlate',
        type: 'String',
        note: 'License Plate of Vehicle'
      },
      {
        name: 'state',
        type: 'String',
        note: 'License Plate State of Vehicle'
      },
      {
        name: 'vin',
        type: 'String',
        note: 'VIN of Vehicle'
      },
      {
        name: 'notes',
        type: 'String',
        note: 'Notes for Vehicle'
      },
      {
        name: 'unitNumber',
        type: 'String',
        note: 'Unit Number for Vehicle'
      }
    ],
    attributes: [
      {
        name: 'data',
        note: 'Saved vehicle'
      }
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">POST </span>
        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/vehicles`}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Content-Type: application/json" \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}" \</span>
        <br />
        <span className="__json-key__">-d </span>
        <span className="__json-string__">'&#123;</span>
        <span className="__json-string__">"customerId": 228770, </span>
        <span className="__json-string__">"year": 2020, </span>
        <span className="__json-string__">"make": "Nissan", </span>
        <span className="__json-string__">"model": "Pathfinder", </span>
        <span className="__json-string__">"subModel": "LSX", </span>
        <span className="__json-string__">"vin": "WDBSK74F46F114815", </span>
        <span className="__json-string__">"licensePlate": "06GUAVB", </span>
        <span className="__json-string__">"state": "TX"</span>
        <span className="__json-string__">&#125;'</span>
      </pre>
    ),
    response: {
      type: 'SUCCESS',
      message: 'Vehicle created',
      data: {
        id: 548052,
        customerId: 228770,
        year: 2020,
        make: 'Nissan',
        model: 'Pathfinder',
        subModel: 'LSX',
        engine: null,
        color: null,
        licensePlate: '06GUAVB',
        state: 'TX',
        vin: null,
        driveType: null,
        transmission: null,
        bodyType: null,
        notes: null,
        unitNumber: null,
        createdDate: '2021-06-30T15:40:15.469Z',
        updatedDate: '2021-06-30T15:40:15.469Z',
        productionDate: null,
        deletedDate: null
      }
    }
  },
  {
    name: 'Update Vehicle',
    method: 'PATCH',
    url: '/api/v1/vehicles/{id}',
    notes: 'Update Vehicle Fields',
    headers: [
      {
        name: 'Content-Type',
        note: 'application/json',
        value: 'application/json'
      },
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [],
    requestAttributes: [
      {
        name: 'year',
        type: 'Integer',
        note: 'Year of Vehicle'
      },
      {
        name: 'make',
        type: 'String',
        note: 'Make of Vehicle'
      },
      {
        name: 'model',
        type: 'String',
        note: 'Model of Vehicle'
      },
      {
        name: 'subModel',
        type: 'String',
        note: 'SubModel of Vehicle'
      },
      {
        name: 'engine',
        type: 'String',
        note: 'Engine of Vehicle'
      },
      {
        name: 'color',
        type: 'String',
        note: 'Color of Vehicle'
      },
      {
        name: 'licensePlate',
        type: 'String',
        note: 'License Plate of Vehicle'
      },
      {
        name: 'state',
        type: 'String',
        note: 'License Plate State of Vehicle'
      },
      {
        name: 'vin',
        type: 'String',
        note: 'VIN of Vehicle'
      },
      {
        name: 'notes',
        type: 'String',
        note: 'Notes for Vehicle'
      },
      {
        name: 'unitNumber',
        type: 'String',
        note: 'Unit Number for Vehicle'
      }
    ],
    attributes: [
      {
        name: 'data',
        note: 'Saved vehicle'
      }
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">PATCH </span>
        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/vehicles/643641`}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Content-Type: application/json" \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}" \</span>
        <br />
        <span className="__json-key__">-d </span>
        <span className="__json-string__">'&#123;</span>
        <span className="__json-string__">"year": "2006"</span>
        <span className="__json-string__">&#125;'</span>
      </pre>
    ),
    response: {
      type: 'SUCCESS',
      message: 'Vehicle Saved',
      data: {
        id: 1,
        customerId: 258819,
        year: 2006,
        make: 'Ford',
        model: 'Escape',
        subModel: 'XLT',
        engine: '3.0L V6 (1) GAS FI',
        color: 'blue',
        licensePlate: 'tag-no',
        state: 'TX',
        vin: '',
        driveType: 'AWD',
        transmission: 'Automatic',
        bodyType: 'Wagon',
        notes: null,
        unitNumber: null,
        createdDate: '2019-02-27T10:31:59Z',
        updatedDate: '2019-02-28T10:32:28Z',
        deletedDate: '2019-03-28T10:32:28Z'
      }
    }
  },
  {
    name: 'Appointments',
    method: 'GET',
    url: '/api/v1/appointments',
    notes: 'Returns a list of all appointments filtered by the provided search parameters',
    headers: [
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [
      {
        name: 'shop',
        type: 'Integer',
        note: 'Search for appointments by shop',
        value: 1
      },
      {
        name: 'customerId',
        type: 'Integer',
        note: 'Specify a customerId to search appointments'
      },
      {
        name: 'vehicleId',
        type: 'Integer',
        note: 'Specify a vehicleId to search appointments'
      },
      {
        name: 'start',
        type: 'Date',
        note: 'Specify a start date to filter appointments'
      },
      {
        name: 'end',
        type: 'Date',
        note: 'Specify a end date to filter appointments'
      },
      {
        name: 'updatedDateStart',
        type: 'Date',
        note: 'Filter by appointment updated date'
      },
      {
        name: 'updatedDateEnd',
        type: 'Date',
        note: 'Filter by appointment updated date'
      },
      {
        name: 'includeDeleted',
        type: 'Boolean',
        note: 'Filter out deleted appointments',
        default: 'true'
      },
      {
        name: 'sort',
        type: 'String',
        note: "Specify the property you'd like to sort on"
      },
      ...paginationParams
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">GET </span>

        <span className="__json-string__">'{url || 'https://sandbox.tekmetric.com/api/v1/appointments?shop=1'}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}"</span>
      </pre>
    ),
    response: {
      content: [
        {
          id: 1,
          shopId: 1,
          customerId: 1,
          vehicleId: 2,
          startTime: '2018-02-04T19:54:38',
          endTime: '2018-02-04T20:24:38',
          description: 'A/C Smells Funny, Oil leak in driveway, Blinker light is out',
          arrived: null,
          appointmentStatus: 'NONE',
          createdDate: '2019-02-27T10:31:59Z',
          updatedDate: '2019-02-28T10:32:28Z',
          deletedDate: null,
          leadSource: null,
          rideOption: null,
          dropoffTime: '2018-02-04T17:00:00',
          pickupTime: '2018-02-04T21:00:00',
          appointmentOption: null
        },
        {
          id: 2,
          shopId: 1,
          customerId: 1,
          vehicleId: 2,
          startTime: '2018-02-06T17:24:52',
          endTime: '2018-02-06T17:54:52',
          description: 'A/C smell, noise on bumps, and ',
          arrived: true,
          appointmentStatus: 'ARRIVED',
          createdDate: '2019-02-27T10:31:59Z',
          updatedDate: '2019-02-28T10:32:28Z',
          deletedDate: '2019-02-28T10:32:28Z',
          leadSource: 'Drive-By',
          rideOption: {
            id: 3,
            code: 'NONE',
            name: 'None'
          },
          dropoffTime: '2018-02-06T12:00:00',
          pickupTime: '2018-02-04T19:00:00',
          appointmentOption: {
            id: 1,
            code: 'STAY',
            name: 'Stay With Vehicle'
          }
        }
      ],
      pageable: {
        sort: {
          sorted: true,
          unsorted: false,
          empty: false
        },
        offset: 0,
        pageSize: 10,
        pageNumber: 0,
        unpaged: false,
        paged: true
      },
      totalElements: 73,
      totalPages: 8,
      last: false,
      size: 2,
      number: 0,
      sort: {
        sorted: true,
        unsorted: false,
        empty: false
      },
      first: true,
      numberOfElements: 2,
      empty: false
    }
  },

  {
    name: 'Appointment',
    method: 'GET',
    url: '/api/v1/appointments/{id}',
    notes: 'Returns an Appointment by ID',
    headers: [
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [
      {
        name: 'id',
        type: 'Integer',
        note: 'appointmentId',
        pathParam: true,
        value: 1
      }
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">GET </span>

        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/appointments/1`}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}"</span>
      </pre>
    ),
    response: {
      id: 1,
      shopId: 1,
      customerId: 1,
      vehicleId: 2,
      startTime: '2018-02-04T19:54:38',
      endTime: '2018-02-04T20:24:38',
      description: 'A/C Smells Funny, Oil leak in driveway, Blinker light is out',
      arrived: null,
      appointmentStatus: 'NONE',
      createdDate: '2019-02-27T10:31:59Z',
      updatedDate: '2019-02-28T10:32:28Z',
      deletedDate: null,
      leadSource: 'Drive-By',
      rideOption: {
        id: 1,
        code: 'RIDE ',
        name: 'Ride Required'
      },
      dropoffTime: '2018-02-15:00:00',
      pickupTime: '2018-02-16T18:00:00',
      appointmentOption: {
        id: 2,
        code: 'DROP',
        name: 'Drop-off Vehicle'
      }
    }
  },
  {
    name: 'Create Appointment',
    method: 'POST',
    url: '/api/v1/appointments',
    notes: 'Creates an Appointment',
    headers: [
      {
        name: 'Content-Type',
        note: 'application/json',
        value: 'application/json'
      },
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [],
    requestAttributes: [
      {
        name: 'shopId',
        type: 'Integer',
        note: 'Shop id',
        required: true
      },
      {
        name: 'customerId',
        type: 'Integer',
        note: 'Customer id - not required (unless there is vehicle) by must belong to shopId'
      },
      {
        name: 'vehicleId',
        type: 'Integer',
        note: 'Vehicle id - not required (unless there is customer) by must belong to customerId. If customerId is not provided it must belong to a customer of the shop.'
      },
      {
        name: 'startTime',
        type: 'DateTime',
        note: 'Start time of the appointment',
        required: true
      },
      {
        name: 'endTime',
        type: 'DateTime',
        note: 'End time of the appointment',
        required: true
      },
      {
        name: 'title',
        type: 'String',
        note: 'Title of the appointment',
        required: true
      },
      {
        name: 'description',
        type: 'String',
        note: 'Description'
      },
      {
        name: 'color',
        type: 'String',
        note: 'Color which will be shown with the Appointment',
        default: 'navy',
        values: [
          { label: 'red', value: 'red' },
          { label: 'pink', value: 'pink' },
          { label: 'yellow', value: 'yellow' },
          { label: 'orange', value: 'orange' },
          { label: 'light green', value: 'light green' },
          { label: 'green', value: 'green' },
          { label: 'blue', value: 'blue' },
          { label: 'navy', value: 'navy' },
          { label: 'lavender', value: 'lavender' },
          { label: 'purple', value: 'purple' }
        ]
      },
      {
        name: 'dropoffTime',
        type: 'DateTime',
        note: 'Vehicle Drop-off time',
        required: false
      },
      {
        name: 'pickupTime',
        type: 'DateTime',
        note: 'Vehicle Pick-up time',
        required: false
      },
      {
        name: 'rideOption',
        type: 'String',
        note: 'Ride option for the appointments that are DROP',
        default: 'NONE',
        values: [
          { label: 'LOANER', value: 'LOANER' },
          { label: 'RIDE', value: 'RIDE' },
          { label: 'NONE', value: 'NONE' }
        ]
      }
    ],
    attributes: [
      {
        name: 'data',
        note: 'id of the saved Appointment'
      }
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">POST </span>
        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/appointments`}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Content-Type: application/json" \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}" \</span>
        <br />
        <span className="__json-key__">-d </span>
        <span className="__json-string__">'&#123;"shopId":1, "customerId":229769, </span>
        <br />
        <span className="__json-string__">
          "startTime": "2020-05-20T16:15:17Z", "endTime": "2020-05-20T16:45:17Z",{' '}
        </span>
        <br />
        <span className="__json-string__">
          "title": "appointment title", "description": "appointment description",{' '}
        </span>
        <br />
        <span className="__json-string__">color": "yellow" &#125;' </span>
      </pre>
    ),
    response: {
      type: 'SUCCESS',
      message: 'Appointment Saved',
      data: 1
    }
  },
  {
    name: 'Update Appointment',
    method: 'PATCH',
    url: '/api/v1/appointments/{id}',
    notes: 'Updates an Appointment',
    headers: [
      {
        name: 'Content-Type',
        note: 'application/json',
        value: 'application/json'
      },
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [],
    requestAttributes: [
      {
        name: 'shopId',
        type: 'Integer',
        note: 'Shop id',
        required: true
      },
      {
        name: 'customerId',
        type: 'Integer',
        note: 'Customer id - not required (unless there is vehicle) by must belong to shopId'
      },
      {
        name: 'vehicleId',
        type: 'Integer',
        note: 'Vehicle id - not required (unless there is customer) by must belong to customerId. If customerId is not provided it must belong to a customer of the shop.'
      },
      {
        name: 'startTime',
        type: 'DateTime',
        note: 'Start time of the appointment',
        required: true
      },
      {
        name: 'endTime',
        type: 'DateTime',
        note: 'End time of the appointment',
        required: true
      },
      {
        name: 'title',
        type: 'String',
        note: 'Title of the appointment',
        required: true
      },
      {
        name: 'description',
        type: 'String',
        note: 'Description'
      },
      {
        name: 'color',
        type: 'String',
        note: 'Color which will be shown with the Appointment',
        default: 'navy',
        values: [
          { label: 'red', value: 'red' },
          { label: 'pink', value: 'pink' },
          { label: 'yellow', value: 'yellow' },
          { label: 'orange', value: 'orange' },
          { label: 'light green', value: 'light green' },
          { label: 'green', value: 'green' },
          { label: 'blue', value: 'blue' },
          { label: 'navy', value: 'navy' },
          { label: 'lavender', value: 'lavender' },
          { label: 'purple', value: 'purple' }
        ]
      },
      {
        name: 'dropoffTime',
        type: 'DateTime',
        note: 'Vehicle Drop-off time',
        required: false
      },
      {
        name: 'pickupTime',
        type: 'DateTime',
        note: 'Vehicle Pick-up time',
        required: false
      },
      {
        name: 'rideOption',
        type: 'String',
        note: 'Ride option for the appointments that are DROP',
        default: 'NONE',
        values: [
          { label: 'LOANER', value: 'LOANER' },
          { label: 'RIDE', value: 'RIDE' },
          { label: 'NONE', value: 'NONE' }
        ]
      }
    ],
    attributes: [
      {
        name: 'data',
        note: 'id of the saved Appointment'
      }
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">PATCH </span>
        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/appointments/10613`}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Content-Type: application/json" \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}" \</span>
        <br />
        <span className="__json-key__">-d </span>
        <span className="__json-string__">'&#123;"customerId":229769, </span>
        <br />
        <span className="__json-string__">
          "startTime": "2020-05-20T16:15:17Z", "endTime": "2020-05-20T16:45:17Z" &#125;'
        </span>
      </pre>
    ),
    response: {
      type: 'SUCCESS',
      message: 'Appointment Updated',
      data: {
        id: 10613,
        shopId: 1,
        customerId: 229769,
        vehicleId: 318807,
        startTime: '2020-05-20T16:15:17Z',
        endTime: '2020-05-20T16:45:17',
        description: 'leaking brake fluid\r\n',
        title: 'Title',
        color: '#33B679',
        arrived: true,
        appointmentStatus: 'ARRIVED',
        createdDate: null,
        updatedDate: '2020-05-20T12:08:08Z',
        deletedDate: null,
        leadSource: 'Yelp',
        rideOption: {
          id: 2,
          code: 'LOANDER ',
          name: 'Loaner Required'
        },
        dropoffTime: '2020-05-20T11:00:00Z',
        pickupTime: '2020-05-20T19:15:00Z',
        appointmentOption: {
          id: 2,
          code: 'DROP',
          name: 'Drop-off Vehicle'
        }
      },
      details: {}
    }
  },
  {
    name: 'Delete Appointment',
    method: 'DELETE',
    url: '/api/v1/appointments/{id}',
    notes: 'Deletes an Appointment by ID',
    headers: [
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [
      {
        name: 'id',
        type: 'Integer',
        note: 'appointmentId',
        pathParam: true,
        value: 1
      }
    ],
    attributes: [
      {
        name: 'data',
        note: 'id of the deleted Appointment'
      }
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">DELETE </span>

        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/appointments/1`}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}"</span>
      </pre>
    ),
    response: {
      type: 'SUCCESS',
      message: 'Appointment Deleted',
      data: 1
    }
  },
  {
    name: 'Employees',
    method: 'GET',
    url: '/api/v1/employees',
    notes: 'Returns a list of all employees filtered by the provided search parameters',
    headers: [
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [
      {
        name: 'shop',
        type: 'Integer',
        note: 'Search for employees by shop',
        value: 1
      },
      {
        name: 'search',
        type: 'String',
        note: 'Search for employees by name'
      },
      {
        name: 'updatedDateStart',
        type: 'Date',
        note: 'Filter by employee updated date'
      },
      {
        name: 'updatedDateEnd',
        type: 'Date',
        note: 'Filter by employee updated date'
      },
      {
        name: 'sort',
        type: 'String',
        note: "Specify the property you'd like to sort on"
      },
      ...paginationParams
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">GET </span>

        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/employees?shop=1`}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}"</span>
      </pre>
    ),
    response: {
      content: [
        {
          id: 780,
          email: 'malindawintheiser@mail.test',
          firstName: 'Malinda',
          lastName: 'Wintheiser',
          address: {
            id: 200418,
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            streetAddress: '',
            fullAddress: ''
          },
          employeeRole: {
            id: 2,
            code: '2',
            name: 'Service Advisor'
          },
          canPerformWork: true,
          createdDate: '2019-02-27T10:31:59Z',
          updatedDate: '2019-02-28T10:32:28Z',
          certificationNumber: 'CT2023-12345'
        }
      ],
      pageable: {
        sort: {
          unsorted: false,
          sorted: true,
          empty: false
        },
        offset: 0,
        pageSize: 10,
        pageNumber: 0,
        paged: true,
        unpaged: false
      },
      totalPages: 1,
      totalElements: 3,
      last: true,
      size: 10,
      number: 0,
      first: true,
      sort: {
        unsorted: false,
        sorted: true,
        empty: false
      },
      numberOfElements: 3,
      empty: false
    }
  },
  {
    name: 'Employee',
    method: 'GET',
    url: '/api/v1/employees/{id}',
    notes: 'Returns an Employee by ID',
    headers: [
      {
        name: 'Authorization',
        note: 'Bearer access_token',
        value: access_token
      }
    ],
    parameters: [
      {
        name: 'id',
        type: 'Integer',
        note: 'employeeId',
        pathParam: true,
        value: 1
      }
    ],
    request: url => (
      <pre>
        <span className="__json-key__">curl -X </span>
        <span className="__json-boolean__">GET </span>

        <span className="__json-string__">'{url || `https://sandbox.tekmetric.com/api/v1/employees/1`}' \</span>
        <br />
        <span className="__json-key__">-H </span>
        <span className="__json-string__">"Authorization: {access_token}"</span>
      </pre>
    ),
    response: {
      id: 780,
      email: 'malindawintheiser@mail.test',
      firstName: 'Malinda',
      lastName: 'Wintheiser',
      address: {
        id: 200418,
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        streetAddress: '',
        fullAddress: ''
      },
      employeeRole: {
        id: 2,
        code: '2',
        name: 'Service Advisor'
      },
      canPerformWork: true,
      createdDate: '2019-02-27T10:31:59Z',
      updatedDate: '2019-02-28T10:32:28Z',
      certificationNumber: 'CT2023-12345'
    }
  }
];

export default endpoints;
