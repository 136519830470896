import React from 'react';
import { Link } from 'react-scroll';
import { Section } from '../../util/styles';

export default function Auth(props) {
  return (
    <Section>
      <h2>Authentication</h2>
      <p>
        The Tekmetric API uses access tokens to authenticate requests. You can obtain an access token and authenticate
        requests by following the steps below: <br />
        <ol>
          <li>
            Complete the API Application form&nbsp;
            <a href="https://docs.google.com/forms/d/e/1FAIpQLScPeuYOzuELMFuSqzFI1S39SaxbRZQ8Of6ZxNFTqRUcUCTKGQ/viewform?vc=0&c=0&w=1&flr=0">
              linked here.
            </a>
            &nbsp;Once your application is reviewed, you will be contacted with next steps.
          </li>
          <li>
            Exchange the provided Client ID and Client Secret for an access token using the{' '}
            <Link smooth offset={-80} duration={500} to="endpoint-0">
              Token Endpoint
            </Link>
            .
          </li>
          <li>
            Provide the generated access token as the bearer token value in each request to the API. The token will
            continue to be valid until it is revoked. If access to additional resources is requested or if access to a
            resource is removed, the same access token can still be used after authorization to the resource is granted
            or removed.
            <br />
            <code>-H "Authorization: Bearer 7de937e1-8574-4459-a0cc-bb4505e7803f"</code>
          </li>
        </ol>
      </p>
    </Section>
  );
}
