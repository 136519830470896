//@flow
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { mainRoutes } from '../../routes';

class Home extends Component<> {
  componentDidMount() {
    if (this.props.location.hash) {
      scroller.scrollTo(this.props.location.hash.replace('#', ''), {
        delay: 200,
        offset: -70,
        duration: 500,
        smooth: true
      });
    }
  }

  render() {
    return (
      <div>
        {mainRoutes.map((route, i) => (
          <div id={route.id} name={route.id}>
            <Route key={i} path={route.path} exact={route.exact} render={props => <route.component {...props} />} />
          </div>
        ))}
      </div>
    );
  }
}

export default Home;
