import React, { useEffect } from 'react';
import { Section, StyledPre, StyledListItem } from '../../../util/styles';
import Divider from '@material-ui/core/Divider';
import { Link as NavLink } from 'react-router-dom';

export default function Changelog(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <Section>
        <h2>Changelog</h2>
        <Divider/>

        <div>
          <h3>July 25, 2024</h3>
          <ul>
            <StyledListItem>
              <StyledPre>phones</StyledPre> and <StyledPre>emails</StyledPre> added to the
              <NavLink to="/#endpoint-6"> Customer Update API</NavLink>
            </StyledListItem>
          </ul>
        </div>

        <div>
          <h3>August 24, 2023</h3>
          <ul>
            <StyledListItem>
              <StyledPre>certificationNumber</StyledPre> added to the <NavLink
                to="/#endpoint-25">Employeee</NavLink> and{' '}
              <NavLink to="/#endpoint-24">Employees</NavLink> object.
            </StyledListItem>
          </ul>
        </div>

        <div>
          <h3>March 8, 2023</h3>
          <ul>
            <StyledListItem>
              <StyledPre>repairOrderCustomLabel</StyledPre> added to the <NavLink to="/#endpoint-8">Repair
              Order</NavLink>{' '}
              and <NavLink to="/#endpoint-7">Repair Orders</NavLink> object.
            </StyledListItem>
            <StyledListItem>
              <StyledPre>color</StyledPre> added to the <NavLink to="/#endpoint-8">Repair Order</NavLink> and{' '}
              <NavLink to="/#endpoint-7">Repair Orders</NavLink> object.
            </StyledListItem>
          </ul>
        </div>

        <div>
          <h3>April 30, 2022</h3>
          <ul>
            <StyledListItem>
              <StyledPre>dropoffTime</StyledPre> and <StyledPre>pickupTime</StyledPre> added to the{' '}
              <NavLink to="/#endpoint-20">Appointment</NavLink> object.
            </StyledListItem>
            <StyledListItem>
              <StyledPre>dropoffTime</StyledPre>, <StyledPre>pickupTime</StyledPre> and <StyledPre>rideOption</StyledPre>{' '}
              added to the <NavLink to="/#endpoint-21">Create Appointment</NavLink> endpoint and{' '}
              <NavLink to="/#endpoint-22">Update Appointment</NavLink> endpoint.
            </StyledListItem>
          </ul>
        </div>

        <div>
          <h3>Aug 25, 2021</h3>
          <ul>
            <StyledListItem>
              <StyledPre>loggedHours</StyledPre> added to the <NavLink to="/#endpoint-12">Update Job</NavLink> endpoint.
            </StyledListItem>
          </ul>
        </div>

        <div>
          <h3>July 26, 2021</h3>
          <ul>
            <StyledListItem>
              <NavLink to="/#endpoint-5">Create Customer</NavLink> endpoint added.
            </StyledListItem>
            <StyledListItem>
              <NavLink to="/#endpoint-6">Update Customer</NavLink> endpoint added.
            </StyledListItem>
            <StyledListItem>
              <NavLink to="/#endpoint-17">Create Vehicle</NavLink> endpoint added.
            </StyledListItem>
            <StyledListItem>
              <StyledPre>technicianId</StyledPre> added to the <NavLink to="/#endpoint-12">Update
              Job</NavLink> endpoint.
            </StyledListItem>
            <StyledListItem>
              <NavLink to="/#endpoint-22">Update Appointment</NavLink> endpoint added.
            </StyledListItem>
          </ul>
        </div>

        <div>
          <h3>April 18, 2021</h3>
          <ul>
            <StyledListItem>
              <NavLink to="/#endpoint-9">Update Repair Order</NavLink> endpoint added.
            </StyledListItem>
            <StyledListItem>
              <NavLink to="/#endpoint-12">Update Job</NavLink> endpoint added.
            </StyledListItem>
            <StyledListItem>
              <NavLink to="/#endpoint-18">Update Vehicle</NavLink> endpoint added.
            </StyledListItem>
            <StyledListItem>
              <StyledPre>canPeformWork</StyledPre> added to the <NavLink to="/#endpoint-24">Employees</NavLink> object
              to
              indicate employee can be assigned to jobs.
            </StyledListItem>
            <StyledListItem>
              <StyledPre>driveType</StyledPre>, &nbsp; <StyledPre>transmission</StyledPre>, &nbsp;
              <StyledPre>bodyType</StyledPre>&nbsp; added to the <NavLink to="/#endpoint-16">Vehicle</NavLink> object
            </StyledListItem>
            <StyledListItem>
              <StyledPre>sort</StyledPre> added to the <NavLink to="/#endpoint-11">Job</NavLink> object
            </StyledListItem>
          </ul>
        </div>

        <div id="cl-2019">
          <h3>November 29, 2020</h3>
          <ul>
            <StyledListItem>
              <NavLink to="/#endpoint-21">Create Appointment</NavLink> endpoint added.
            </StyledListItem>
            <StyledListItem>
              <NavLink to="/#endpoint-23">Delete Appointment</NavLink> endpoint added.
            </StyledListItem>
            <StyledListItem>
              <StyledPre>deletedDate</StyledPre> added to the <NavLink to="/#endpoint-20">Appointment</NavLink> object
              to
              indicate objects that have been deleted in the system. Deleted objects are no longer searchable within
              Tekmetric and are filtered out of most operations. A <StyledPre>deletedDate</StyledPre> of{' '}
              <StyledPre>null</StyledPre> indicates that the object is still active in the system.
            </StyledListItem>
            <StyledListItem>
              <StyledPre>includeDeleted</StyledPre> search criteria added to the{' '}
              <NavLink to="/#endpoint-19">Appointment Search</NavLink> endpoint.
            </StyledListItem>
            <StyledListItem>
              <StyledPre>jobCategoryName</StyledPre> added to the <NavLink to="/#endpoint-11">Job</NavLink> object to
              indicate the category of the job.
            </StyledListItem>
            <StyledListItem>
              <StyledPre>completedDate</StyledPre> added to the <NavLink to="/#endpoint-11">Job</NavLink> object to
              indicate completetion date of the job.
            </StyledListItem>
          </ul>

          <h3>September 14, 2020</h3>
          <ul>
            <StyledListItem>
              <StyledPre>primary</StyledPre> added to the <NavLink to="/#endpoint-4">Customer</NavLink> object.
              Represents
              which phone is marked as the primary phone for the customer.
            </StyledListItem>
          </ul>

          <h3>March 15, 2020</h3>
          <ul>
            <StyledListItem>
              <StyledPre>estimateShareDate</StyledPre> added to the <NavLink to="/#endpoint-8">Repair
              Order</NavLink>{' '}
              object. Represents the last date the estimate was shared with the customer.
            </StyledListItem>
            <StyledListItem>
              <StyledPre>leadSource</StyledPre> added to the <NavLink to="/#endpoint-8">Repair Order</NavLink> object.
            </StyledListItem>
          </ul>

          <h3>December 17, 2019</h3>
          <ul>
            <StyledListItem>
              <StyledPre>laborHours</StyledPre> added to the <NavLink to="/#endpoint-11">Job</NavLink> object to
              indicate
              the total labor hours on that job.
            </StyledListItem>
            <StyledListItem>
              <StyledPre>loggedHours</StyledPre> added to the <NavLink to="/#endpoint-11">Job</NavLink> object to
              indicate
              the total hours logged by the technician on that job.
            </StyledListItem>
          </ul>

          <h3>October 27, 2019</h3>
          <ul>
            <StyledListItem>
              <StyledPre>deletedDate</StyledPre> added to the <NavLink to="/#endpoint-4">Customer</NavLink>,{' '}
              <NavLink to="/#endpoint-8">Repair Order</NavLink>, and <NavLink
                to="/#endpoint-16">Vehicle</NavLink> objects
              to indicate objects that have been deleted in the system. Deleted objects are no longer searchable within
              Tekmetric and are filtered out of most operations. A <StyledPre>deletedDate</StyledPre> of{' '}
              <StyledPre>null</StyledPre> indicates that the object is still active in the system.
            </StyledListItem>
            <StyledListItem>
              <StyledPre>deletedDateStart</StyledPre> and <StyledPre>deletedDateEnd</StyledPre> search criteria added to
              the <NavLink to="/#endpoint-3">Customer Search</NavLink>,{' '}
              <NavLink to="/#endpoint-7">Repair Order Search</NavLink>, and{' '}
              <NavLink to="/#endpoint-15">Vehicle Search</NavLink> endpoints.
            </StyledListItem>
            <StyledListItem>
              <StyledPre>DELETED</StyledPre> Repair Order Status added to{' '}
              <NavLink to="/#endpoint-8">Repair Order</NavLink> object.
            </StyledListItem>
          </ul>

          <h3>September 26, 2019</h3>
          <ul>
            <StyledListItem>
              <StyledPre>postedDateStart</StyledPre> and <StyledPre>postedDateEnd</StyledPre> search criteria added to
              the{' '}
              <NavLink to="/#endpoint-7">Repair Order Search</NavLink> endpoint.
            </StyledListItem>
          </ul>

          <h3>April 23, 2019</h3>
          <ul>
            <StyledListItem>
              <StyledPre>updatedDate</StyledPre> and <StyledPre>createdDate</StyledPre> added to the{' '}
              <NavLink to="/#endpoint-4">Customer</NavLink>, <NavLink to="/#endpoint-8">Repair Order</NavLink>,{' '}
              <NavLink to="/#endpoint-11">Job</NavLink>, <NavLink to="/#endpoint-16">Vehicle</NavLink>,{' '}
              <NavLink to="/#endpoint-20">Appointment</NavLink>, and <NavLink to="/#endpoint-25">Employee</NavLink>{' '}
              objects.
            </StyledListItem>
            <StyledListItem>
              <StyledPre>updatedDateStart</StyledPre> and <StyledPre>updatedDateEnd</StyledPre> search criteria added to
              the <NavLink to="/#endpoint-3">Customer Search</NavLink>,{' '}
              <NavLink to="/#endpoint-7">Repair Order Search</NavLink>, <NavLink to="/#endpoint-10">Job
              Search</NavLink>,{' '}
              <NavLink to="/#endpoint-15">Vehicle Search</NavLink>,{' '}
              <NavLink to="/#endpoint-19">Appointment Search</NavLink>, and{' '}
              <NavLink to="/#endpoint-24">Employee Search</NavLink> endpoints.
            </StyledListItem>
          </ul>

          <h3>April 5, 2019</h3>
          <ul>
            <StyledListItem>
              <NavLink to="/#endpoint-10">Job Search</NavLink> endpoint added.
            </StyledListItem>
          </ul>

          <h3>March 24, 2019</h3>
          <ul>
            <StyledListItem>
              <NavLink to="/#endpoint-25">Employee</NavLink> and <NavLink to="/#endpoint-24">Employee
              Search</NavLink>{' '}
              endpoints added.
            </StyledListItem>
          </ul>

          <h3>March 17, 2019</h3>
          <ul>
            <StyledListItem>
              <NavLink to="/#endpoint-20">Appointment</NavLink> and{' '}
              <NavLink to="/#endpoint-19">Appointment Search</NavLink> endpoints added.
            </StyledListItem>
          </ul>

          <h3>March 3, 2019</h3>
          <ul>
            <StyledListItem>Version 1 of Tekmetric API released.</StyledListItem>
          </ul>
        </div>
      </Section>
  );
}
