import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import headerImg from '../../images/api.png';

function Header(props) {
  return (
    <div>
      <AppBar>
        <Toolbar>
          <div>
            <Link to="/">
              <img src={headerImg} alt="Tekmetric API" />
            </Link>
          </div>
          <div>
            <Link to="/demo">
              <Button variant="raised" color="secondary">
                Test Drive
              </Button>
            </Link>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
