//@flow
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import JSONPretty from 'react-json-pretty';
import { Section, APIWrapper, Docs, Code, CodeWrapper } from '../../util/styles';

const httpCodes = [
  {
    name: '200',
    description: 'Everything worked as expected'
  },
  {
    name: '400',
    description: 'Bad request. Request has invalid parameters.'
  },
  {
    name: '401',
    description: 'Unauthorized. Invalid client credentials or access token provided.'
  },
  {
    name: '403',
    description: 'Forbidden. Provided access token does not have sufficient privileges to requested resource.'
  },
  {
    name: '404',
    description: 'Requested resource not found.'
  },
  {
    name: '429',
    description: 'Too many requests. Rate limit exceeded.'
  },
  {
    name: '5xx',
    description: `Error on Tekmetric's end.`
  }
];

const errorTypes = [
  {
    name: 'invalid_token',
    description: 'Invalid access token provided to API.'
  },
  {
    name: 'insufficient_scope',
    description: 'Provided access token does not have sufficient privileges to requested resource.'
  },
  {
    name: 'invalid_client',
    description: 'Invalid client credentials or grant type provided to API.'
  }
];

export default function ErrorCodes(props) {
  return (
    <Section>
      <h2>Error Responses</h2>
      <APIWrapper>
        <Docs>
          <div>
            <Typography>
              Tekmetric uses standard HTTP response codes to indicate the success or failure of an API request.
              Tekmetric will return 200, 4xx, and 5xx status codes.
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="30%">HTTP Status Code</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {httpCodes.map(c => (
                    <TableRow>
                      <TableCell>
                        <strong>{c.name}</strong>
                      </TableCell>
                      <TableCell>{c.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="30%">Error Types</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {errorTypes.map(t => (
                    <TableRow>
                      <TableCell>
                        <strong>{t.name}</strong>
                      </TableCell>
                      <TableCell>{t.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <br />
            </Typography>
          </div>
        </Docs>
        <CodeWrapper>
          <Code>
            <h3>Example Error Response</h3>
            <JSONPretty
              data={{
                error: 'insufficient_scope',
                error_description: 'Insufficient scope for this resource',
                scope: '791'
              }}
            />
          </Code>
        </CodeWrapper>
      </APIWrapper>
    </Section>
  );
}
