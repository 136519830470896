// @flow
import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

import styled from 'styled-components';
import Header from './components/Header';
import Footer from './components/Footer';
import SideBar from './components/Sidebar';
import routes from './routes';

class App extends Component<Props, State> {
  render() {
    return (
      <Fragment>
        <Header />
        <Main>
          <FixedNav>
            <SideBar routes={routes} />
          </FixedNav>
          <Content>
            <Switch>
              {routes.map((route, i) =>
                route.component ? (
                  <Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    render={props => <route.component {...props} />}
                  />
                ) : (
                  <Route key={i} {...route} {...this.props} />
                )
              )}

              <Route render={() => <div>Page Not Found</div>} />
            </Switch>
          </Content>
        </Main>

        <Footer />
      </Fragment>
    );
  }
}
export default App;

const Content = styled.div`
  flex: 1 1 auto;
  padding: 12px 24px 48px;
  max-width: 1200px;
  margin: 0 auto;
`;

const FixedNav = styled.div`
  display: none;

  @media (min-width: 600px) {
    display: block;
    flex: 0 0 250px;
    background: rgb(247, 247, 247);
    border-right: 1px solid #e8e8e8;
  }
`;

const Main = styled.div`
  padding-top: 64px;
  flex: 1 1 auto;
  display: flex;
`;
