import { createMuiTheme } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#64b5f6',
      main: '#004e7c',
      dark: '#1976d2'
    },
    secondary: {
      light: orange[300],
      main: '#f05829',
      dark: orange[700]
    }
  },
  overrides: {
    MuiTypography: {
      root: {}
    },
    MuiAppBar: {
      root: {
        '& img': {
          height: 'auto',
          width: 220
        }
      },
      colorPrimary: {
        backgroundColor: '#fff'
      }
    },
    MuiToolbar: {
      root: {
        display: 'flex',
        justifyContent: 'space-between'
      }
    },
    MuiTableCell: {
      root: {
        padding: '4px'
      }
    },
    MuiTableRow: {
      head: {
        height: 36,
        padding: '8px 4px 4px',
        verticalAlign: 'bottom'
      }
    },
    MuiFilledInput: {
      root: {
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        fontSize: '14px'
      },
      input: {
        padding: '10px 12px'
      }
    }
  }
});
