//@flow
import React from 'react';
import { BoldSpan, Section, StyledListItem, StyledPre } from '../../util/styles';

export default function HandlingRateLimiting(props) {
  return (
    <Section>
      <div>
        <h2>Handling 429 errors</h2>
        <p>
          When you hit the rate limit, you will receive a <StyledPre>429 Too Many Requests</StyledPre> response. Here’s
          how to handle it gracefully:
        </p>
        <ul>
          <StyledListItem>
            <BoldSpan>Implement Exponential Backoff</BoldSpan>: Retry requests with increasing wait times between
            attempts to prevent overwhelming the server.
          </StyledListItem>
          <StyledListItem>
            <BoldSpan>Monitor Your Usage</BoldSpan>: Keep track of your request rate and adjust your application to stay
            within the limits.
          </StyledListItem>
          <StyledListItem>
            <BoldSpan>Optimize Requests</BoldSpan>: Cache responses and avoid redundant requests to reduce the load on
            the API.
          </StyledListItem>
        </ul>
        <p>
          For more advanced handling, consider implementing a token bucket algorithm on the client-side. This method
          allows you to control the rate of requests by using tokens that are replenished over time.
        </p>

        <h2>Exponential Backoff</h2>
        <p>
          Exponential backoff is a common strategy for handling rate limits and network congestion. It works by
          increasing the wait time between retries after each failed request, up to a maximum limit. This approach helps
          prevent overwhelming the server with repeated requests.
        </p>
        <p>
          Here’s how exponential backoff works:
        </p>
        <ul>
          <StyledListItem>
            Make a request to the API.
          </StyledListItem>
          <StyledListItem>
            If the request fails, wait <StyledPre> 2 + random_number_milliseconds</StyledPre> seconds and retry the
            request.
          </StyledListItem>
          <StyledListItem>
            If the request fails, wait <StyledPre> 4 + random_number_milliseconds</StyledPre> seconds and retry the
            request.
          </StyledListItem>
          <StyledListItem>
            If the request fails, wait <StyledPre> 8 + random_number_milliseconds</StyledPre> seconds and retry the
            request.
          </StyledListItem>
          <StyledListItem>
            And so on, up to a <StyledPre>maximum_backoff</StyledPre> time.
          </StyledListItem>
          <StyledListItem>
            Continue waiting and retrying up to some maximum number of retries, but do not increase the wait period
            between retries.
          </StyledListItem>
        </ul>

        <p>
          Where:
        </p>
        <ul>
          <StyledListItem>
            The wait time is <StyledPre>min(((2^n)+random_number_milliseconds), maximum_backoff)</StyledPre>, with n
            incremented by 1 for each iteration (request), and starting with n=1.
          </StyledListItem>
          <StyledListItem>
            <StyledPre>random_number_milliseconds</StyledPre> is a random number of milliseconds less than or equal
            to <StyledPre>1000</StyledPre>.
          </StyledListItem>
          <StyledListItem>
            <StyledPre>maximum_backoff</StyledPre> is typically <StyledPre>60</StyledPre> seconds.
          </StyledListItem>
        </ul>
      </div>
    </Section>
  );
}
