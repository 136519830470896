//@flow
import React, { Component, Fragment } from 'react';
import JSONPretty from 'react-json-pretty';
import InputLabel from '@material-ui/core/InputLabel';
import { DatePicker } from 'material-ui-pickers';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styled from 'styled-components';

import fetch from '../../../util/fetch';
import { URLWrapper, ParamType, Method, URL, Request, APIWrapper, Docs, Code, CodeWrapper } from '../../../util/styles';
import endpoints, { setDefaults } from '../../../util/endpoints';
import { jsonToParam, updatePathWithParams } from '../../../util/general';

const host = process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : 'https://sandbox.tekmetric.com';

class Sandbox extends Component<Props, State> {
  state = {
    fetching: false,
    endpointIndex: 0,
    payloadAttributes: {},
    ...setDefaults(endpoints[0])
  };

  handleRequest = e => {
    e.preventDefault();

    this.setState({ fetching: true });

    let endpoint = endpoints[this.state.endpointIndex];
    const paramsUrlPart = Object.keys(this.state.parameters).length > 0 ? `?${jsonToParam({ ...this.state.parameters })}` : '';
    const requestBody = endpoint.method === 'POST' ? 
      this.state.payloadAttributes && Object.keys(this.state.payloadAttributes).length > 0 ? 
      JSON.stringify(this.state.payloadAttributes) : "{}" : null;
    
    fetch(
      `${host + this.state.url}${paramsUrlPart}`,
      {
        method: endpoint.method
      },
      this.state.headers,
      requestBody
    )
      .then(response => {
        this.setState({ response, fetching: false });
      })
      .catch(error => {
        console.log(error);
        this.setState({ response: error, fetching: false });
      });
  };

  endpointChange = e => {
    this.setState({ endpointIndex: e.target.value, response: null, ...setDefaults(endpoints[e.target.value]), payloadAttributes: {} });
  };

  generateURL = (url, params) => {
    let pathParams = endpoints[this.state.endpointIndex].parameters
      .filter(param => !param.pathParam)
      .reduce((obj, param) => ({ ...obj, [param.name]: params[param.name] }), {});

    return Object.keys(pathParams).length > 0 ? `${url}?${jsonToParam(pathParams)}` : url;
  };

  paramChange = (param, value) => {
    let parameters = { ...this.state.parameters, [param]: value };
    let pathParams = endpoints[this.state.endpointIndex].parameters
      .filter(param => param.pathParam === true)
      .map(param => ({ ...param, value: parameters[param.name] }));

    this.setState({
      parameters,
      url: updatePathWithParams(endpoints[this.state.endpointIndex].url, pathParams)
    });
  };

  requestAttributesChange = (param, value) => {
    let requestAttributes = { ...this.state.payloadAttributes, [param]: value };
    if(requestAttributes && (value == null || value === "")) {
      delete requestAttributes[param]
    }
    this.setState({
      payloadAttributes: requestAttributes
    });
  };

  render() {
    const endpoint = endpoints[this.state.endpointIndex];
    const { url, headers, parameters, response, endpointIndex, fetching, payloadAttributes } = this.state;

    return (
      <div>
        <h2>API Demo</h2>
        <Divider />
        <br />

        <div>
          <APIWrapper>
            <Docs>
              <StyledCard>
                <form autoComplete="off" onSubmit={this.handleRequest}>
                  <FormControl fullWidth>
                    <InputLabel>Endpoint</InputLabel>
                    <Select filled value={endpointIndex} onChange={this.endpointChange}>
                      {endpoints.map((e, i) => (
                        <MenuItem key={e.name} value={i}>
                          {e.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Table padding="dense">
                    {endpoint.headers && (
                      <Fragment>
                        <TableHead>
                          <TableRow>
                            <TableCell colspan={2}>Headers</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {endpoint.headers.map((header, i) => (
                            <TableRow key={header.name}>
                              <TableCell>
                                <strong>{header.name}</strong>
                              </TableCell>
                              <TableCell>
                                <FilledInput
                                  fullWidth
                                  name={header.name}
                                  value={headers[header.name]}
                                  placeholder={header.name}
                                  onChange={e => {
                                    this.setState({ headers: { ...headers, [header.name]: e.target.value } });
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Fragment>
                    )}
                    {endpoint.parameters && endpoint.parameters.length > 0 && endpoint.method !== 'POST' && (
                      <Fragment>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>Parameters</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {endpoint.parameters.map((param, i) => (
                            <TableRow key={param.name}>
                              <TableCell>
                                <strong>{param.name}</strong>
                                <br />
                                <ParamType>{param.type}</ParamType>
                              </TableCell>
                              <TableCell>
                                {param.type === 'date' ? (
                                  <FormControl variant="filled" fullWidth>
                                    <DatePicker
                                      keyboard
                                      clearable
                                      mask={value =>
                                        value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                                      }
                                      format="MM/dd/yyyy"
                                      variant="filled"
                                      placeholder={param.name}
                                      value={parameters[param.name] || null}
                                      onChange={date => this.paramChange(param.name, date ? date.toISOString() : '')}
                                    />
                                  </FormControl>
                                ) : param.values ? (
                                  <FormControl variant="filled" fullWidth>
                                    <Select
                                      value={parameters[param.name]}
                                      placeholder={param.name}
                                      onChange={e =>
                                        this.paramChange(param.name, param.isArray ? [e.target.value] : e.target.value)
                                      }
                                      input={<FilledInput name={param.name} id={param.name} />}>
                                      <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem>
                                      {param.values.map((val, i) => (
                                        <MenuItem key={i} value={val.value}>
                                          {val.value} {val.value !== val.label && ` - (${val.label})`}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                ) : (
                                      <FilledInput
                                        fullWidth
                                        name={param.name}
                                        value={parameters[param.name]}
                                        placeholder={param.name}
                                        onChange={e => this.paramChange(param.name, e.target.value)}
                                      />
                                    )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Fragment>
                    )}
                    {endpoint.requestAttributes && endpoint.requestAttributes.length > 0 && endpoint.method === 'POST' && (
                      <Fragment>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>Request Attributes</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {endpoint.requestAttributes.map((param, i) => (
                            <TableRow key={param.name}>
                              <TableCell>
                                <strong>{param.name}</strong>
                                <br />
                                <ParamType>{param.type}</ParamType>
                              </TableCell>
                              <TableCell>
                                {param.type === 'date' ? (
                                  <FormControl variant="filled" fullWidth>
                                    <DatePicker
                                      keyboard
                                      clearable
                                      mask={value =>
                                        value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                                      }
                                      format="MM/dd/yyyy"
                                      variant="filled"
                                      placeholder={param.name}
                                      value={payloadAttributes[param.name]}
                                      onChange={date => this.requestAttributesChange(param.name, date ? date.toISOString() : '')}
                                    />
                                  </FormControl>
                                ) : param.values ? (
                                  <FormControl variant="filled" fullWidth>
                                    <Select
                                      value={payloadAttributes[param.name]}
                                      placeholder={param.name}
                                      onChange={e =>
                                        this.requestAttributesChange(param.name, e.target.value)
                                      }
                                      input={<FilledInput name={param.name} id={param.name} />}>
                                      <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem>
                                      {param.values.map((val, i) => (
                                        <MenuItem key={i} value={val.value}>
                                          {val.value} {val.value !== val.label && ` - (${val.label})`}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                ) : (
                                  <FilledInput
                                    fullWidth
                                    name={param.name}
                                    value={payloadAttributes[param.name]}
                                    placeholder={param.name}
                                    onChange={e => this.requestAttributesChange(param.name, e.target.value)}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Fragment>
                    )}
                  </Table>
                  <br />
                  <br />
                  <div>
                    <Button variant="raised" color="primary" type="submit" disabled={fetching}>
                      Send
                    </Button>
                  </div>
                </form>
              </StyledCard>
            </Docs>

            <CodeWrapper>
              <URLWrapper>
                <Method method={endpoint.method}>{endpoint.method}</Method>
                <URL>{url}</URL>
              </URLWrapper>
              <Code>
                <h3>Example Request</h3>
                <Request>{endpoint.request(this.generateURL(host + url, this.state.parameters))}</Request>

                <h3>Response</h3>
                {!response ? (
                  <div>Submit a request to the API to see the response</div>
                ) : (
                    <JSONPretty data={response} />
                  )}
              </Code>
            </CodeWrapper>
          </APIWrapper>
        </div>
      </div>
    );
  }
}

export default Sandbox;

const StyledCard = styled(Card)`
  padding: 24px;
`;
